import React from 'react'

import Cookie from '../../images/1x/follow-the-cookie-fs8.png'

const FollowTheCookie = () => (
  <div className="FollowTheCookie pos-relative">
    <img src={Cookie} alt="" className="FollowTheCookie__img" />
    <div className="FollowTheCookie__copy">
      Scroll for some fun facts about
      <br />
      the holidays and technology.
    </div>
  </div>
)

export default FollowTheCookie
