export default [
  'M211.3,6.4c3.3,1.4,4.6,5.8,2.9,9.9a.8.8,0,0,1-.2.4h.3a3.9,3.9,0,0,0,2.1.3,3.7,3.7,0,0,0,2.1.3,4.4,4.4,0,0,0,2-.8,8.3,8.3,0,0,0,3.3-2.6,3.4,3.4,0,0,0,1.3-1.6,6.9,6.9,0,0,0,.3-2v-.2a4.6,4.6,0,0,0,.2-2,2.9,2.9,0,0,0-.7-1.9,8.6,8.6,0,0,0-2.6-3.4,3.7,3.7,0,0,0-1.7-1.3,3.9,3.9,0,0,0-2.1-.3,4.7,4.7,0,0,0-2.1-.3,4.1,4.1,0,0,0-2.1,1,8.9,8.9,0,0,0-2.9,2.2A3.4,3.4,0,0,0,209.9,6Z',
  'M209.6,26.3a3.2,3.2,0,0,0-1.2-.8,3.3,3.3,0,0,0-1.7.1,6.8,6.8,0,0,0-2.3.5l-.5.2a14.7,14.7,0,0,1,1.7,9.8,1.7,1.7,0,0,0,1,0,5.5,5.5,0,0,0,2.8-.6,2.9,2.9,0,0,0,1.2-.7,2.5,2.5,0,0,0,.7-1.2,2.3,2.3,0,0,0,.8-1.2,2.9,2.9,0,0,0,0-1.6,4.6,4.6,0,0,0-.6-2.3,2.6,2.6,0,0,0-.7-1.5A3.7,3.7,0,0,0,209.6,26.3Z',
  'M234,30.3a2.4,2.4,0,0,0,1-1,2.3,2.3,0,0,0,.3-1.6,6.5,6.5,0,0,0-.1-2.4v-.5a15.5,15.5,0,0,1-10-.3,1.8,1.8,0,0,0-.2,1,5.3,5.3,0,0,0,.1,2.8,2.3,2.3,0,0,0,.4,1.4,2.4,2.4,0,0,0,1.1.9,2.4,2.4,0,0,0,1,1,2.7,2.7,0,0,0,1.5.3,6.5,6.5,0,0,0,2.4-.1,2.9,2.9,0,0,0,1.6-.4A3.1,3.1,0,0,0,234,30.3Z',
  'M179.2,78a3.1,3.1,0,0,0,1.1.9,2.3,2.3,0,0,0,1.6.2,4.8,4.8,0,0,0,2.4-.3h.6a14.4,14.4,0,0,1-.6-10h-1.1a5.5,5.5,0,0,0-2.8.4,2.4,2.4,0,0,0-1.3.5,2.8,2.8,0,0,0-.8,1.1,3.1,3.1,0,0,0-.9,1.1,3,3,0,0,0-.2,1.6,6.7,6.7,0,0,0,.4,2.4,2.3,2.3,0,0,0,.5,1.5A2.1,2.1,0,0,0,179.2,78Z',
  'M144,89.9a2.3,2.3,0,0,0-.8-1.2,3,3,0,0,0-1.5-.5,5.1,5.1,0,0,0-2.4-.4h-.5a15.2,15.2,0,0,1-2.2,9.8,3.2,3.2,0,0,0,.9.3,5.4,5.4,0,0,0,2.8.5,2.3,2.3,0,0,0,1.4-.2,1.5,1.5,0,0,0,1.1-.8,2.5,2.5,0,0,0,1.2-.8,3.2,3.2,0,0,0,.6-1.5,6.1,6.1,0,0,0,.4-2.4,3,3,0,0,0-.1-1.6A3.1,3.1,0,0,0,144,89.9Z',
  'M165,103a1.9,1.9,0,0,0,1.3-.5,2.4,2.4,0,0,0,.9-1.4,4.5,4.5,0,0,0,.8-2.2,1.4,1.4,0,0,0,.2-.6,14,14,0,0,1-9.1-4.1,2.4,2.4,0,0,0-.6.9,5.1,5.1,0,0,0-.9,2.7,2.2,2.2,0,0,0-.2,1.4,2.3,2.3,0,0,0,.6,1.2,2.6,2.6,0,0,0,.6,1.3,2.2,2.2,0,0,0,1.3.9,6.3,6.3,0,0,0,2.3.8,2.2,2.2,0,0,0,1.6.2A1.7,1.7,0,0,0,165,103Z',
  'M141,117.6a2.4,2.4,0,0,0,.7,1.2,2.6,2.6,0,0,0,1.5.7,4.6,4.6,0,0,0,2.3.6h.6a14.5,14.5,0,0,1,2.9-9.5l-.9-.5a5.1,5.1,0,0,0-2.7-.6,1.7,1.7,0,0,0-1.4,0,2.4,2.4,0,0,0-1.2.7,3.5,3.5,0,0,0-1.3.8,2.5,2.5,0,0,0-.6,1.4,4.6,4.6,0,0,0-.6,2.3,2.2,2.2,0,0,0,0,1.6A2.4,2.4,0,0,0,141,117.6Z',
  'M7.8,246.1a3.1,3.1,0,0,0-.6,1.3,4.1,4.1,0,0,0,.3,1.6,6.2,6.2,0,0,0,1,2.2l.2.5a15.1,15.1,0,0,1,9.4-3.4,1.6,1.6,0,0,0-.2-1,5.4,5.4,0,0,0-1.1-2.6,2.8,2.8,0,0,0-.8-1.1,2.7,2.7,0,0,0-1.4-.5,2.1,2.1,0,0,0-1.3-.5,2,2,0,0,0-1.5.3,4.3,4.3,0,0,0-2.2.9,2.6,2.6,0,0,0-1.3,1A2.1,2.1,0,0,0,7.8,246.1Z',
  'M31.5,260.8a2.2,2.2,0,0,0,1.4.2,2.6,2.6,0,0,0,1.5-.7,6.2,6.2,0,0,0,1.9-1.4l.4-.4a14.8,14.8,0,0,1-5.6-8.3,2.5,2.5,0,0,0-.9.4,4.9,4.9,0,0,0-2.2,1.8,2,2,0,0,0-.9,1.1,2.2,2.2,0,0,0-.2,1.4,3.2,3.2,0,0,0-.2,1.4,2.7,2.7,0,0,0,.7,1.4,4.5,4.5,0,0,0,1.5,1.9,2,2,0,0,0,1.2,1A2.2,2.2,0,0,0,31.5,260.8Z',
  'M6.2,268.9c3.4.9,5.3,5.1,4.2,9.4v.4c0,.1.1.1.2.1a4.1,4.1,0,0,0,2.2,0,3.7,3.7,0,0,0,2.1,0,4.2,4.2,0,0,0,1.8-1.1,8.3,8.3,0,0,0,3-2.9,4,4,0,0,0,1-1.8,3.4,3.4,0,0,0,0-2v-.3a3.4,3.4,0,0,0,0-2,4,4,0,0,0-1-1.8,8.3,8.3,0,0,0-3-2.9,4.2,4.2,0,0,0-1.8-1.1,4.1,4.1,0,0,0-2.2,0,3.8,3.8,0,0,0-2.1,0,3.9,3.9,0,0,0-2,1.3A7.5,7.5,0,0,0,6,266.7a3.9,3.9,0,0,0-1.3,2Z',
  'M7.1,288.8a1.9,1.9,0,0,0-1.3-.5,3,3,0,0,0-1.6.2,4.7,4.7,0,0,0-2.2.9l-.5.2a15.1,15.1,0,0,1,3,9.5,1.6,1.6,0,0,0,1-.1,6.3,6.3,0,0,0,2.7-1,3.2,3.2,0,0,0,1.7-2.2,1.9,1.9,0,0,0,.5-1.3,2.5,2.5,0,0,0-.2-1.5,5,5,0,0,0-.9-2.3,2.2,2.2,0,0,0-.9-1.3A3.1,3.1,0,0,0,7.1,288.8Z',
  'M31.9,289.5a1.8,1.8,0,0,0,.8-1.1,3,3,0,0,0,.1-1.6,6.7,6.7,0,0,0-.4-2.4c0-.2-.1-.3-.1-.5a15.8,15.8,0,0,1-10,1.1,1.7,1.7,0,0,0,0,1,5.6,5.6,0,0,0,.5,2.8,1.9,1.9,0,0,0,.6,1.3l1.1.8a3.6,3.6,0,0,0,1.2.8,2.9,2.9,0,0,0,1.6.1,4.6,4.6,0,0,0,2.3-.5,2,2,0,0,0,1.5-.6A2,2,0,0,0,31.9,289.5Z',
  'M18.6,314.3a3.5,3.5,0,0,0,1.2.7,2.9,2.9,0,0,0,1.6-.2,4.8,4.8,0,0,0,2.3-.7h.5a14.9,14.9,0,0,1-2.2-9.8H20.9a5.6,5.6,0,0,0-2.7.8,3.7,3.7,0,0,0-1.2.7,3.8,3.8,0,0,0-.6,1.3,2.7,2.7,0,0,0-.7,1.3,2.6,2.6,0,0,0,.1,1.5,6.2,6.2,0,0,0,.7,2.3,2.9,2.9,0,0,0,.8,1.4A2.4,2.4,0,0,0,18.6,314.3Z',
  'M33,334c3.3-1.4,7.3.9,9,5,0,.1.1.3.1.4h.3a5.5,5.5,0,0,0,1.7-1.3,5.2,5.2,0,0,0,1.7-1.3,3.9,3.9,0,0,0,.8-2,7.1,7.1,0,0,0,.6-4.1,3.4,3.4,0,0,0-.2-2,4.3,4.3,0,0,0-1.2-1.7v-.2a6.1,6.1,0,0,0-1.2-1.6,5.2,5.2,0,0,0-1.9-.8,8.6,8.6,0,0,0-4.2-.6,5,5,0,0,0-2.1.2,4.5,4.5,0,0,0-1.7,1.3,3.7,3.7,0,0,0-1.7,1.3,3.6,3.6,0,0,0-.9,2.2,8.3,8.3,0,0,0-.5,3.5,3.3,3.3,0,0,0,.2,2.4A2.7,2.7,0,0,1,33,334Z',
  'M45.6,349.4a3.5,3.5,0,0,0-1.4.3,2.5,2.5,0,0,0-1.1,1.2,5.3,5.3,0,0,0-1.3,2l-.3.5a14.8,14.8,0,0,1,8.1,5.9,6,6,0,0,0,.8-.8,4.9,4.9,0,0,0,1.5-2.4,2.8,2.8,0,0,0,.4-1.3,2.3,2.3,0,0,0-.4-1.4,1.8,1.8,0,0,0-.3-1.4,2.3,2.3,0,0,0-1.1-1.1,4.6,4.6,0,0,0-2-1.3,2.7,2.7,0,0,0-1.5-.5A3.2,3.2,0,0,0,45.6,349.4Z',
  'M49.3,375.8a2,2,0,0,0-.9,1.1,2.2,2.2,0,0,0-.2,1.6,4.9,4.9,0,0,0,.2,2.4,1.1,1.1,0,0,0,.1.5,14.9,14.9,0,0,1,10-.3,3.4,3.4,0,0,0,.1-1,4.5,4.5,0,0,0-.3-2.8,2.4,2.4,0,0,0-.5-1.4,1.8,1.8,0,0,0-1.1-.8,2.2,2.2,0,0,0-1.1-1,2.6,2.6,0,0,0-1.5-.2,5,5,0,0,0-2.4.3,2.1,2.1,0,0,0-1.5.5A2.4,2.4,0,0,0,49.3,375.8Z',
  'M83.5,379.5a2.4,2.4,0,0,0,.8-1.1,2.2,2.2,0,0,0,0-1.6,5.1,5.1,0,0,0-.4-2.4.9.9,0,0,0-.1-.5,14.8,14.8,0,0,1-9.9,1.1,3.4,3.4,0,0,0-.1,1,5.6,5.6,0,0,0,.5,2.8,3.8,3.8,0,0,0,.6,1.3,3.6,3.6,0,0,0,1.2.8l1.1.8a3,3,0,0,0,1.6.1,5.2,5.2,0,0,0,2.4-.5,2.4,2.4,0,0,0,1.5-.6A3.6,3.6,0,0,0,83.5,379.5Z',
  'M81.9,403.7a3.5,3.5,0,0,0,1,1.1,2.1,2.1,0,0,0,1.5.3,4.9,4.9,0,0,0,2.4.1h.6a14.9,14.9,0,0,1,.9-10l-1-.2a5.3,5.3,0,0,0-2.8-.1,3.2,3.2,0,0,0-1.4.3,2.4,2.4,0,0,0-1,1,3.6,3.6,0,0,0-1.1,1,2.1,2.1,0,0,0-.3,1.5,4.9,4.9,0,0,0-.1,2.4,2.2,2.2,0,0,0,.3,1.6A3.2,3.2,0,0,0,81.9,403.7Z',
  'M109.3,410.6a2.8,2.8,0,0,0-1.2-.8,2.2,2.2,0,0,0-1.6,0,5.2,5.2,0,0,0-2.4.5h-.5a15.6,15.6,0,0,1,1.5,9.9h1a4.3,4.3,0,0,0,2.7-.6,3.1,3.1,0,0,0,1.3-.6,2.1,2.1,0,0,0,.7-1.2,2,2,0,0,0,.8-1.2,2.2,2.2,0,0,0,0-1.6,3.7,3.7,0,0,0-.5-2.3,2.8,2.8,0,0,0-.6-1.5A3.7,3.7,0,0,0,109.3,410.6Z',
  'M90.2,431a1.9,1.9,0,0,0-.5,1.3,2.9,2.9,0,0,0,.4,1.6,5.8,5.8,0,0,0,1,2.1l.3.5a14.8,14.8,0,0,1,9.2-3.8,3,3,0,0,0-.2-1,5.3,5.3,0,0,0-1.3-2.6,1.8,1.8,0,0,0-.9-1,3.5,3.5,0,0,0-1.3-.5,3.3,3.3,0,0,0-1.4-.4,2.7,2.7,0,0,0-1.5.3,5.3,5.3,0,0,0-2.1,1.1,2.6,2.6,0,0,0-1.3,1A2.3,2.3,0,0,0,90.2,431Z',
  'M119.1,436.6a2.3,2.3,0,0,0-1.4.1,3,3,0,0,0-1.3.9,4.7,4.7,0,0,0-1.5,1.9.8.8,0,0,0-.4.4,14.4,14.4,0,0,1,7.2,7,2.7,2.7,0,0,0,.8-.7,5.5,5.5,0,0,0,1.9-2.1,3.1,3.1,0,0,0,.6-1.3,2.2,2.2,0,0,0-.2-1.4,2.3,2.3,0,0,0-.1-1.4,3,3,0,0,0-.9-1.3,4.7,4.7,0,0,0-1.9-1.5,2.2,2.2,0,0,0-1.4-.8A2.3,2.3,0,0,0,119.1,436.6Z',
  'M121.3,464.6a3.3,3.3,0,0,0,0,1.4,2.5,2.5,0,0,0,.8,1.4,5.2,5.2,0,0,0,1.6,1.7c.1.2.3.3.4.4a14.7,14.7,0,0,1,7.8-6.3l-.5-.9a5.5,5.5,0,0,0-2-2,2.5,2.5,0,0,0-1.2-.8,3.3,3.3,0,0,0-1.4,0,2.2,2.2,0,0,0-1.4-.1,2.9,2.9,0,0,0-1.4.8,5.2,5.2,0,0,0-1.7,1.6,2.6,2.6,0,0,0-.9,1.4A2.2,2.2,0,0,0,121.3,464.6Z',
  'M137.3,488.5a2.6,2.6,0,0,0-2.1,1.9,4.9,4.9,0,0,0-.8,2.3c-.1.2-.2.3-.2.5a15.4,15.4,0,0,1,9.2,4,4.1,4.1,0,0,0,.5-.9,5.2,5.2,0,0,0,1-2.6,3.8,3.8,0,0,0,.1-1.5,2.7,2.7,0,0,0-.6-1.2,3.1,3.1,0,0,0-.6-1.3,2.9,2.9,0,0,0-1.4-.8,4.3,4.3,0,0,0-2.2-.9,3,3,0,0,0-1.6-.2A2.7,2.7,0,0,0,137.3,488.5Z',
  'M139.3,504.1a2.3,2.3,0,0,0-1.4.1,3,3,0,0,0-1.3.9,5.8,5.8,0,0,0-1.5,1.9l-.4.4a14.4,14.4,0,0,1,7.2,7,2.7,2.7,0,0,0,.8-.7,4.8,4.8,0,0,0,1.9-2.1,3.1,3.1,0,0,0,.6-1.3,20,20,0,0,1-.3-2.8,2.4,2.4,0,0,0-.9-1.3,5.2,5.2,0,0,0-1.8-1.5,2.8,2.8,0,0,0-1.5-.8A2.3,2.3,0,0,0,139.3,504.1Z',
  'M162.7,519.2a3.9,3.9,0,0,0,1.5-.2,2.3,2.3,0,0,0,1.1-1.1,4.8,4.8,0,0,0,1.4-2c.2-.1.3-.2.3-.4a15.6,15.6,0,0,1-7.7-6.4,2.3,2.3,0,0,0-.7.7,5.5,5.5,0,0,0-1.7,2.3,2.9,2.9,0,0,0-.5,1.3,3.2,3.2,0,0,0,.3,1.4,2.2,2.2,0,0,0,.2,1.4,2.9,2.9,0,0,0,1,1.2,4.8,4.8,0,0,0,2,1.4,2,2,0,0,0,1.4.6A2.2,2.2,0,0,0,162.7,519.2Z',
  'M174.4,535.4c-3.4,1.2-7.3-1.4-8.7-5.6,0-.1-.1-.3-.1-.4h-.3a4.3,4.3,0,0,0-1.7,1.2,4.4,4.4,0,0,0-1.9,1.2,3.4,3.4,0,0,0-.9,1.9,8.2,8.2,0,0,0-.9,4.1,4.4,4.4,0,0,0,.1,2,4.2,4.2,0,0,0,1.1,1.7c.1.1.1.2.2.2a3.2,3.2,0,0,0,1.1,1.7,3.1,3.1,0,0,0,1.8.9,7.3,7.3,0,0,0,4.1.9,3,3,0,0,0,2.1-.1,3.6,3.6,0,0,0,1.8-1.2,2.9,2.9,0,0,0,1.8-1.1,3.5,3.5,0,0,0,1-2.1,7.3,7.3,0,0,0,.7-3.6,3,3,0,0,0,0-2.3A5.2,5.2,0,0,1,174.4,535.4Z',
  'M153.1,566.2a2.3,2.3,0,0,0,1.4-.1,2.7,2.7,0,0,0,1.2-.9,5.5,5.5,0,0,0,1.6-1.8l.3-.4a14,14,0,0,1-6.6-7,1.8,1.8,0,0,0-.9.6,5.3,5.3,0,0,0-1.8,2.1,2.3,2.3,0,0,0-.6,1.2,2.2,2.2,0,0,0,.1,1.4,3.3,3.3,0,0,0,0,1.4,3,3,0,0,0,.9,1.3,5,5,0,0,0,1.7,1.5,2.2,2.2,0,0,0,1.3.8A3.2,3.2,0,0,0,153.1,566.2Z',
  'M131.4,576.7a3.2,3.2,0,0,0-.3,1.4,2.6,2.6,0,0,0,.7,1.5,4.6,4.6,0,0,0,1.3,1.8c.1.2.3.3.4.4a14.5,14.5,0,0,1,8.1-5.4,3.6,3.6,0,0,0-.4-.9,5,5,0,0,0-1.6-2.2,2.4,2.4,0,0,0-1.1-.9,2,2,0,0,0-1.3-.1,2.2,2.2,0,0,0-1.4-.2,2,2,0,0,0-1.3.6,4.5,4.5,0,0,0-1.9,1.4,2.3,2.3,0,0,0-1,1.2A2.4,2.4,0,0,0,131.4,576.7Z',
  'M144,601.7a2.9,2.9,0,0,0-1.3.5,2.8,2.8,0,0,0-1,1.2,4.9,4.9,0,0,0-1,2.2.8.8,0,0,0-.2.5,13.6,13.6,0,0,1,8.3,4.8,2.1,2.1,0,0,0,.6-.8,5.7,5.7,0,0,0,1.2-2.6,2.8,2.8,0,0,0,.3-1.3,1.9,1.9,0,0,0-.5-1.3,2.3,2.3,0,0,0-.4-1.4,2.7,2.7,0,0,0-1.2-.9,5.1,5.1,0,0,0-2.1-1,2.4,2.4,0,0,0-1.5-.4Z',
  'M170.7,604a2.2,2.2,0,0,0-.1-1.4,2.5,2.5,0,0,0-.9-1.3,6.5,6.5,0,0,0-1.8-1.5l-.4-.3a15.1,15.1,0,0,1-6.7,7.2l.6.8a4.4,4.4,0,0,0,2.1,1.7,1.7,1.7,0,0,0,1.2.6,3.2,3.2,0,0,0,1.4-.1,2.9,2.9,0,0,0,1.3-.1,2.4,2.4,0,0,0,1.2-1,4.3,4.3,0,0,0,1.5-1.8,2.9,2.9,0,0,0,.8-1.4A3.2,3.2,0,0,0,170.7,604Z',
  'M142.5,642.8a3.1,3.1,0,0,0-1.1.9,2.9,2.9,0,0,0-.4,1.6,6.4,6.4,0,0,0-.1,2.4v.5a15.2,15.2,0,0,1,9.9,1.1,1.6,1.6,0,0,0,.3-.9,4.8,4.8,0,0,0,.1-2.9,2,2,0,0,0-.2-1.3,3.6,3.6,0,0,0-1-1.1,1.9,1.9,0,0,0-1-1,2.5,2.5,0,0,0-1.5-.5,6.4,6.4,0,0,0-2.4-.1,3.2,3.2,0,0,0-1.6.3A3.2,3.2,0,0,0,142.5,642.8Z',
  'M166.5,648.9a2.7,2.7,0,0,0,1.2.9,2.9,2.9,0,0,0,1.6.1,4.5,4.5,0,0,0,2.3-.4h.6a14.7,14.7,0,0,1-.9-9.9h-1a5.5,5.5,0,0,0-2.8.4,3.8,3.8,0,0,0-1.3.6,2.8,2.8,0,0,0-.8,1.1,2.7,2.7,0,0,0-.9,1.2,2.6,2.6,0,0,0-.1,1.5,6.4,6.4,0,0,0,.4,2.4,2.2,2.2,0,0,0,.6,1.5A2.1,2.1,0,0,0,166.5,648.9Z',
  'M163,668.6c-3.2-1.7-4.1-6.2-2-10.1.1-.1.1-.3.2-.4H161a4.1,4.1,0,0,0-2.1-.5,4.6,4.6,0,0,0-2.1-.5,3.7,3.7,0,0,0-2,.7,7.5,7.5,0,0,0-3.6,2.2,4.7,4.7,0,0,0-1.3,1.5,3.5,3.5,0,0,0-.5,2c0,.1,0,.1-.1.2a4.5,4.5,0,0,0-.4,2,3.9,3.9,0,0,0,.6,1.9,7.7,7.7,0,0,0,2.3,3.6,4.3,4.3,0,0,0,1.5,1.5,5.2,5.2,0,0,0,2.1.4,4.6,4.6,0,0,0,2.1.5,3.5,3.5,0,0,0,2.2-.8,7.3,7.3,0,0,0,3-1.9,4,4,0,0,0,1.7-1.7A4.8,4.8,0,0,1,163,668.6Z',
  'M156.4,690.5a2,2,0,0,0,.8-1.2,2.9,2.9,0,0,0,.1-1.6,4.6,4.6,0,0,0-.5-2.3,1.3,1.3,0,0,0-.1-.6,14.2,14.2,0,0,1-9.9,1.2,4.1,4.1,0,0,0-.1,1.1,5.1,5.1,0,0,0,.6,2.7,1.8,1.8,0,0,0,.6,1.3,2.5,2.5,0,0,0,1.2.8,2.3,2.3,0,0,0,1.1.8,2.9,2.9,0,0,0,1.6.1,6.9,6.9,0,0,0,2.4-.5,2.5,2.5,0,0,0,1.4-.6A2.8,2.8,0,0,0,156.4,690.5Z',
  'M154.5,715.9a2.4,2.4,0,0,0-1-1,2.3,2.3,0,0,0-1.6-.3,4.9,4.9,0,0,0-2.4.1H149a14.9,14.9,0,0,1-.3,10l1,.2a5.3,5.3,0,0,0,2.8-.1,2.3,2.3,0,0,0,1.4-.4,2.4,2.4,0,0,0,.9-1.1,2.4,2.4,0,0,0,1-1,2.1,2.1,0,0,0,.3-1.5,6.5,6.5,0,0,0-.1-2.4,2.9,2.9,0,0,0-.4-1.6A2.4,2.4,0,0,0,154.5,715.9Z',
  'M135.4,736.4a3.1,3.1,0,0,0-1.1.9,3,3,0,0,0-.5,1.6,6.3,6.3,0,0,0-.2,2.4v.5a14.8,14.8,0,0,1,9.9,1.5,2.2,2.2,0,0,0,.3-1,5.4,5.4,0,0,0,.2-2.8,2.2,2.2,0,0,0-.2-1.4,2.7,2.7,0,0,0-1-1.1,2.3,2.3,0,0,0-.9-1.1,2.4,2.4,0,0,0-1.5-.4,3.8,3.8,0,0,0-2.4-.2,2.3,2.3,0,0,0-1.6.2A2.2,2.2,0,0,0,135.4,736.4Z',
  'M155.1,762.9c-3.2-1.8-3.9-6.3-1.7-10.2,0-.1.1-.2.2-.4h-.2a3.3,3.3,0,0,0-2.1-.5,3.7,3.7,0,0,0-2.1-.6,3.6,3.6,0,0,0-2,.6,8.2,8.2,0,0,0-3.6,2.1,5.4,5.4,0,0,0-1.5,1.5,4.5,4.5,0,0,0-.5,1.9v.3a3.2,3.2,0,0,0-.5,1.9,4.6,4.6,0,0,0,.5,2,7.5,7.5,0,0,0,2.2,3.6,3.1,3.1,0,0,0,1.5,1.5,2.9,2.9,0,0,0,2,.5,3.7,3.7,0,0,0,2.1.6,4.1,4.1,0,0,0,2.2-.7,7.7,7.7,0,0,0,3.1-1.9,3.5,3.5,0,0,0,1.8-1.6A3.4,3.4,0,0,1,155.1,762.9Z',
  'M128.5,764.3a1.9,1.9,0,0,0-1.3-.5,2.3,2.3,0,0,0-1.6.3,5.1,5.1,0,0,0-2.2.9l-.5.2a15.2,15.2,0,0,1,3.3,9.5l1-.2a4.7,4.7,0,0,0,2.6-1.1,2.7,2.7,0,0,0,1.2-.9,2.9,2.9,0,0,0,.5-1.3,1.9,1.9,0,0,0,.5-1.3,2,2,0,0,0-.3-1.5,4.3,4.3,0,0,0-.9-2.2,2.4,2.4,0,0,0-2.3-1.9Z',
  'M147.8,784.6a2,2,0,0,0,.8-1.2,1.8,1.8,0,0,0,.1-1.6,5,5,0,0,0-.3-2.4,4.3,4.3,0,0,1-.1-.5,15,15,0,0,1-10,.9,3.4,3.4,0,0,0-.1,1,5.4,5.4,0,0,0,.5,2.8,2.1,2.1,0,0,0,.5,1.3,2.8,2.8,0,0,0,1.2.8,3.1,3.1,0,0,0,1.1.9,3,3,0,0,0,1.6.1,6.7,6.7,0,0,0,2.4-.4,2.4,2.4,0,0,0,1.5-.6A2.8,2.8,0,0,0,147.8,784.6Z',
  'M111.5,789.5a2.3,2.3,0,0,0-1.4-.1,2.8,2.8,0,0,0-1.4.9A6.9,6.9,0,0,0,107,792l-.3.4a15.5,15.5,0,0,1,6.6,7.5l.9-.5a5.2,5.2,0,0,0,1.9-2,2.4,2.4,0,0,0,.7-1.3,1.7,1.7,0,0,0,0-1.4,2.7,2.7,0,0,0-.8-2.7,4.6,4.6,0,0,0-1.7-1.7,3.4,3.4,0,0,0-1.4-.9A2.2,2.2,0,0,0,111.5,789.5Z',
  'M144,823.4c-3.5.9-7.1-1.9-8.2-6.2a.9.9,0,0,0-.1-.5h-.2a4,4,0,0,0-1.9,1.1,3.9,3.9,0,0,0-1.9,1,4.9,4.9,0,0,0-1.1,1.8,8.1,8.1,0,0,0-1.1,4.1,3.4,3.4,0,0,0-.1,2,4,4,0,0,0,1,1.8c0,.1,0,.1.1.2a5.1,5.1,0,0,0,.9,1.8,4,4,0,0,0,1.8,1,7.3,7.3,0,0,0,4.1,1.2,3.7,3.7,0,0,0,2.1.1,4.2,4.2,0,0,0,1.8-1.1,3,3,0,0,0,1.9-1,3.1,3.1,0,0,0,1.1-2,8.5,8.5,0,0,0,1.1-3.5,3.6,3.6,0,0,0,.1-2.3Z',
  'M125.7,842.6a2.5,2.5,0,0,0-2.3,1.8,5.5,5.5,0,0,0-1,2.2c-.1.2-.2.3-.2.5a15.7,15.7,0,0,1,8.9,4.6l.6-.8a4.7,4.7,0,0,0,1.1-2.6,2,2,0,0,0,.2-1.3,2.4,2.4,0,0,0-.5-1.4,2.4,2.4,0,0,0-.5-1.3,2,2,0,0,0-1.2-.9,4.8,4.8,0,0,0-2.3-1,2,2,0,0,0-1.5-.3A1.9,1.9,0,0,0,125.7,842.6Z',
  'M153.5,844.2a2.3,2.3,0,0,0-.1-1.4,2.2,2.2,0,0,0-1.1-1.2,4.3,4.3,0,0,0-1.8-1.5l-.5-.3a14.9,14.9,0,0,1-6.6,7.4,1.6,1.6,0,0,0,.6.8,4.6,4.6,0,0,0,2.2,1.7,3.1,3.1,0,0,0,1.3.6,2.2,2.2,0,0,0,1.4-.2,3.8,3.8,0,0,0,1.5-.1,4,4,0,0,0,1.2-1,5.2,5.2,0,0,0,1.4-1.9,2.6,2.6,0,0,0,.7-1.5A2.2,2.2,0,0,0,153.5,844.2Z',
  'M163.5,876a2.3,2.3,0,0,0,1.4-.2,2.5,2.5,0,0,0,1.1-1.2,4.6,4.6,0,0,0,1.3-2l.3-.5a14.5,14.5,0,0,1-8-5.9,2.7,2.7,0,0,0-.8.7,4.9,4.9,0,0,0-1.5,2.4,2,2,0,0,0-.4,1.3,2.2,2.2,0,0,0,.3,1.4,2.4,2.4,0,0,0,.3,1.4,2,2,0,0,0,1.1,1.1,4.6,4.6,0,0,0,2,1.3,3.4,3.4,0,0,0,1.5.6A2.3,2.3,0,0,0,163.5,876Z',
  'M143,890a3.3,3.3,0,0,0,0,1.4,2.6,2.6,0,0,0,.9,1.4,5.2,5.2,0,0,0,1.7,1.6c.1.2.3.3.4.4a15,15,0,0,1,7.4-6.7,4.1,4.1,0,0,0-.5-.9,4.8,4.8,0,0,0-2.1-1.9,1.9,1.9,0,0,0-1.2-.7,3.3,3.3,0,0,0-1.4,0,3.3,3.3,0,0,0-1.4,0,2.5,2.5,0,0,0-1.3.9,4.6,4.6,0,0,0-1.7,1.7,3.4,3.4,0,0,0-.9,1.4A2.2,2.2,0,0,0,143,890Z',
  'M188,911.1a3.2,3.2,0,0,0-.3-1.4,2.5,2.5,0,0,0-1.2-1.1,4.5,4.5,0,0,0-2.1-1.2l-.5-.3a14.6,14.6,0,0,1-5.6,8.3l.7.7a6.8,6.8,0,0,0,2.5,1.4,2.8,2.8,0,0,0,1.3.4,2.3,2.3,0,0,0,1.4-.4,3.5,3.5,0,0,0,1.4-.3,3.2,3.2,0,0,0,1.1-1.2,5.6,5.6,0,0,0,1.2-2,3,3,0,0,0,.5-1.6A2.8,2.8,0,0,0,188,911.1Z',
  'M160.1,913a3.7,3.7,0,0,0-1.2.7,2.9,2.9,0,0,0-.8,1.4,6.2,6.2,0,0,0-.7,2.3,4.3,4.3,0,0,1-.1.5,15.7,15.7,0,0,1,9.4,3.5,4.1,4.1,0,0,0,.5-.9,5,5,0,0,0,.8-2.7,3.3,3.3,0,0,0,0-1.4,1.9,1.9,0,0,0-.7-1.2,3.1,3.1,0,0,0-.6-1.3,2.7,2.7,0,0,0-1.4-.7,4.8,4.8,0,0,0-2.3-.7,3,3,0,0,0-1.6-.2Z',
  'M100.7,920.1c3.3,1.4,4.6,5.8,2.9,9.9,0,.1-.1.2-.2.4h.3a3.9,3.9,0,0,0,2.1.3,3.6,3.6,0,0,0,2.1.3,4,4,0,0,0,2-.9,8.1,8.1,0,0,0,3.3-2.5,5.1,5.1,0,0,0,1.3-1.6,4.7,4.7,0,0,0,.2-2,.4.4,0,0,1,.1-.3,4.4,4.4,0,0,0,.2-2,3.7,3.7,0,0,0-.8-1.8,7.8,7.8,0,0,0-2.5-3.4,3.7,3.7,0,0,0-1.7-1.3,3.9,3.9,0,0,0-2.1-.3,3.8,3.8,0,0,0-4.2.7,7.7,7.7,0,0,0-2.9,2.2,4,4,0,0,0-1.6,1.8Z',
  'M184.1,940.9a2.4,2.4,0,0,0-1.2-.7,2.2,2.2,0,0,0-1.6,0,4.6,4.6,0,0,0-2.3.6h-.5a14.8,14.8,0,0,1,1.7,9.9h1a5.5,5.5,0,0,0,2.8-.6,3.7,3.7,0,0,0,1.2-.7,2.1,2.1,0,0,0,.7-1.2,3.2,3.2,0,0,0,.8-1.2,2.9,2.9,0,0,0-.1-1.6,5.9,5.9,0,0,0-.5-2.3,2.6,2.6,0,0,0-.7-1.5A3.3,3.3,0,0,0,184.1,940.9Z',
  'M96.9,948.9a2.4,2.4,0,0,0,.2,1.4,2.9,2.9,0,0,0,1,1.2A7,7,0,0,0,100,953l.5.3a13.9,13.9,0,0,1,6.6-7.5,2.7,2.7,0,0,0-.7-.8,4.6,4.6,0,0,0-2.2-1.7,3.1,3.1,0,0,0-1.3-.6,3.2,3.2,0,0,0-1.4.2,2.2,2.2,0,0,0-1.4.2,1.8,1.8,0,0,0-1.2,1,4,4,0,0,0-1.5,1.9,2.7,2.7,0,0,0-.7,1.4A2.6,2.6,0,0,0,96.9,948.9Z',
  'M75.2,958a4,4,0,0,0,.7-1.3,3,3,0,0,0-.2-1.6,4.6,4.6,0,0,0-.6-2.3c-.1-.1-.1-.3-.2-.5a14.9,14.9,0,0,1-9.8,2.2,4.1,4.1,0,0,0,.1,1.1,5.6,5.6,0,0,0,.8,2.7,3.7,3.7,0,0,0,.7,1.2,3.1,3.1,0,0,0,1.3.6,1.9,1.9,0,0,0,1.2.7,2.9,2.9,0,0,0,1.6-.1,4.8,4.8,0,0,0,2.3-.7,2.3,2.3,0,0,0,1.4-.8A2.1,2.1,0,0,0,75.2,958Z',
  'M169.5,964.9a2.4,2.4,0,0,0-.9,1.1,2.2,2.2,0,0,0-.1,1.6,5,5,0,0,0,.3,2.4v.5a15,15,0,0,1,10-.5,2.1,2.1,0,0,0,.1-1.1,4.5,4.5,0,0,0-.3-2.8,3.5,3.5,0,0,0-.5-1.3,2,2,0,0,0-1.2-.8,2,2,0,0,0-1.1-.9,2.6,2.6,0,0,0-1.5-.2,4.8,4.8,0,0,0-2.4.3,2.5,2.5,0,0,0-1.5.6A3.1,3.1,0,0,0,169.5,964.9Z',
  'M146.8,970.2a2.4,2.4,0,0,0,.2,1.4,2.9,2.9,0,0,0,1,1.2,5.5,5.5,0,0,0,1.9,1.5l.5.3a15.1,15.1,0,0,1,6.6-7.5,2.7,2.7,0,0,0-.7-.8,5.4,5.4,0,0,0-2.2-1.7,3.1,3.1,0,0,0-1.3-.6,3.2,3.2,0,0,0-1.4.3,2.2,2.2,0,0,0-1.4.1,2.9,2.9,0,0,0-1.2,1,4.5,4.5,0,0,0-1.5,1.9,2.6,2.6,0,0,0-.7,1.5A3.2,3.2,0,0,0,146.8,970.2Z',
  'M173.1,1022.3a2.4,2.4,0,0,0,.7-1.3,2.2,2.2,0,0,0-.1-1.6,4.8,4.8,0,0,0-.7-2.3c0-.2-.1-.3-.1-.5a14.9,14.9,0,0,1-9.8,2.2,3.1,3.1,0,0,0,.1,1,5,5,0,0,0,.8,2.7,1.9,1.9,0,0,0,.7,1.2,42,42,0,0,1,2.5,1.4,2.9,2.9,0,0,0,1.6-.1,6.2,6.2,0,0,0,2.3-.7,2.9,2.9,0,0,0,1.4-.8A3.4,3.4,0,0,0,173.1,1022.3Z',
  'M142,1025.1a3.3,3.3,0,0,0,.4,1.3,2.5,2.5,0,0,0,1.2,1.1,7.2,7.2,0,0,0,2.1,1.2l.5.2a14.9,14.9,0,0,1,5.4-8.4l-.7-.7a7.7,7.7,0,0,0-2.5-1.4,3.2,3.2,0,0,0-1.4-.3,2.8,2.8,0,0,0-1.3.4,2.3,2.3,0,0,0-1.4.4,2.6,2.6,0,0,0-1,1.1,4.8,4.8,0,0,0-1.2,2.2,2.5,2.5,0,0,0-.5,1.5A3.3,3.3,0,0,0,142,1025.1Z',
  'M154,1041.3c3.3,1.4,4.6,5.9,2.9,9.9l-.2.5h.2a3.1,3.1,0,0,0,2.1.2,4.1,4.1,0,0,0,2.2.4,3.4,3.4,0,0,0,1.9-.9,8.4,8.4,0,0,0,3.4-2.5,4.8,4.8,0,0,0,1.2-1.6,3.6,3.6,0,0,0,.3-2v-.3a3.4,3.4,0,0,0,.3-2,4.6,4.6,0,0,0-.8-1.9,7,7,0,0,0-2.6-3.3,2.9,2.9,0,0,0-1.6-1.3,3.9,3.9,0,0,0-2.1-.3,4,4,0,0,0-4.3.7,7.7,7.7,0,0,0-2.9,2.2,3.8,3.8,0,0,0-1.5,1.8A4,4,0,0,1,154,1041.3Z',
]
