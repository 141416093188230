import React from 'react'

import CookieSVG from '../images/SVG/spinning-cookie.svg'
import MistletoeSVG from '../images/SVG/animated-mistletoe.svg'
import Mistletoe2SVG from '../images/SVG/animated-mistletoe2.svg'
import FrameNoAnimations from '../images/1x/frame-no-animations-fs8.png'
import FrameBottomNoAnimations from '../images/SVG/frame-bottom.svg'
import RollingPin from '../images/SVG/rolling-pin.svg'
import Lights from '../images/SVG/lights.svg'

const renderRollingPin = () => {
  return (
    <img src={RollingPin} className="FrameSVGAnimations__rolling-pin" alt="" />
  )
}

const renderCookies = () => {
  return (
    <>
      <img src={CookieSVG} className="FrameSVGAnimations__cookie1" alt="" />
      <img src={CookieSVG} className="FrameSVGAnimations__cookie2" alt="" />
    </>
  )
}

const renderFrameBG = top => {
  return top ? (
    <img
      src={FrameNoAnimations}
      alt=""
      className="FrameSVGAnimations__frame--top"
    />
  ) : (
    <img
      src={FrameBottomNoAnimations}
      alt=""
      className="FrameSVGAnimations__frame--bottom"
    />
  )
}

const FrameSVGAnimations = (top = true) => (
  <div className="FrameSVGAnimations pos-relative">
    {renderFrameBG(top)}

    {top ? renderRollingPin() : null}

    {top ? renderCookies() : null}

    <img src={Lights} className="FrameSVGAnimations__lights" />
    <img src={MistletoeSVG} className="FrameSVGAnimations__mistletoe1" alt="" />
    <img src={MistletoeSVG} className="FrameSVGAnimations__mistletoe2" alt="" />
    <img src={MistletoeSVG} className="FrameSVGAnimations__mistletoe3" alt="" />
    <img src={MistletoeSVG} className="FrameSVGAnimations__mistletoe4" alt="" />
    <img
      src={Mistletoe2SVG}
      className="FrameSVGAnimations__mistletoe5"
      alt=""
    />
    <img
      src={Mistletoe2SVG}
      className="FrameSVGAnimations__mistletoe6"
      alt=""
    />
  </div>
)

export default FrameSVGAnimations
