export default [
  'M68.8,7.7c3.5.7,5.7,4.7,4.9,9.1v.4h.2a5.3,5.3,0,0,0,2.2-.2,5,5,0,0,0,2.1-.1,4.6,4.6,0,0,0,1.8-1.3,8.4,8.4,0,0,0,2.7-3.2,5.1,5.1,0,0,0,.9-1.8,4.4,4.4,0,0,0-.2-2V8.4a3.5,3.5,0,0,0-.2-2A3.9,3.9,0,0,0,82,4.7,8,8,0,0,0,78.7,2a3.1,3.1,0,0,0-1.9-.9,3.1,3.1,0,0,0-2.1.2,3.8,3.8,0,0,0-2.1.1,4.5,4.5,0,0,0-1.9,1.4,7,7,0,0,0-2.3,2.8,3.7,3.7,0,0,0-1.2,2.1A4.3,4.3,0,0,1,68.8,7.7Z',
  'M96,26.3a2.4,2.4,0,0,0,.7-1.2,2.2,2.2,0,0,0,0-1.6,4.6,4.6,0,0,0-.6-2.3c-.1-.2-.1-.4-.2-.6a14.8,14.8,0,0,1-9.8,1.9,1.7,1.7,0,0,0,0,1,5.5,5.5,0,0,0,.7,2.8,3.7,3.7,0,0,0,.7,1.2,3.3,3.3,0,0,0,1.3.7,2.4,2.4,0,0,0,1.2.7,1.9,1.9,0,0,0,1.5,0,5.3,5.3,0,0,0,2.4-.6,2.7,2.7,0,0,0,1.4-.7A2.4,2.4,0,0,0,96,26.3Z',
  'M71.2,27.5a2.8,2.8,0,0,0-1.3-.4,2.9,2.9,0,0,0-1.6.3,5.3,5.3,0,0,0-2.1,1.1l-.5.2A14.7,14.7,0,0,1,69.4,38l1-.2A5.3,5.3,0,0,0,73,36.5a2.2,2.2,0,0,0,1-.9,2.4,2.4,0,0,0,.5-1.3,3.4,3.4,0,0,0,.5-1.4,2.8,2.8,0,0,0-.4-1.5,6.2,6.2,0,0,0-1-2.2,2.9,2.9,0,0,0-1-1.2A3.4,3.4,0,0,0,71.2,27.5Z',
  'M47.2,83.5a2.4,2.4,0,0,0,.9,1.1,2.4,2.4,0,0,0,1.6.5,4.7,4.7,0,0,0,2.4.2c.1,0,.3.1.5,0a14.4,14.4,0,0,1,1.5-9.8,1.6,1.6,0,0,0-.9-.3,4.8,4.8,0,0,0-2.9-.3,3,3,0,0,0-1.3.2,2.7,2.7,0,0,0-1.1,1,2.7,2.7,0,0,0-1.6,2.4,4.7,4.7,0,0,0-.2,2.4,3.1,3.1,0,0,0,.2,1.6A2.2,2.2,0,0,0,47.2,83.5Z',
  'M10.3,87.7a2,2,0,0,0-.6-1.3,2,2,0,0,0-1.3-.9,4.7,4.7,0,0,0-2.2-.9l-.5-.2a15.2,15.2,0,0,1-4.3,9.1l.9.6a5.5,5.5,0,0,0,2.6,1,3.2,3.2,0,0,0,1.4.1,2.2,2.2,0,0,0,1.3-.6,2.1,2.1,0,0,0,1.3-.5,3.8,3.8,0,0,0,.9-1.3,5.7,5.7,0,0,0,.8-2.3,2,2,0,0,0,.3-1.5A2.6,2.6,0,0,0,10.3,87.7Z',
  'M28.1,105a3.5,3.5,0,0,0,1.4-.3,2.5,2.5,0,0,0,1.1-1.2,4.6,4.6,0,0,0,1.3-2l.3-.5a14.8,14.8,0,0,1-8.1-5.9l-.7.7a6.1,6.1,0,0,0-1.5,2.4,3.5,3.5,0,0,0-.5,1.3,2.1,2.1,0,0,0,.4,1.4,1.7,1.7,0,0,0,.3,1.4,2,2,0,0,0,1.1,1.1,3.9,3.9,0,0,0,2,1.3,2,2,0,0,0,1.5.6A3.2,3.2,0,0,0,28.1,105Z',
  'M1.5,114.1a2.4,2.4,0,0,0,.5,1.4,2.9,2.9,0,0,0,1.2,1,6.2,6.2,0,0,0,2.2,1l.5.2a15.2,15.2,0,0,1,4.9-8.7,2.4,2.4,0,0,0-.8-.6,4.9,4.9,0,0,0-2.5-1.2,3.2,3.2,0,0,0-1.4-.3,1.9,1.9,0,0,0-1.3.5,2.3,2.3,0,0,0-1.4.4,3.8,3.8,0,0,0-1,1.2,6.2,6.2,0,0,0-1,2.2,2.5,2.5,0,0,0,.1,2.9Z',
  'M62.1,234.1a2.1,2.1,0,0,0-1.3-.5,2.3,2.3,0,0,0-1.6.4,4.9,4.9,0,0,0-2.2,1l-.4.3a14.4,14.4,0,0,1,3.7,9.2l1-.2a5.8,5.8,0,0,0,2.5-1.2,2.1,2.1,0,0,0,1.1-1,2.1,2.1,0,0,0,.5-1.3,5,5,0,0,0,.5-1.3,2.9,2.9,0,0,0-.4-1.6,4.7,4.7,0,0,0-1.1-2.1,2.4,2.4,0,0,0-.9-1.3A2.5,2.5,0,0,0,62.1,234.1Z',
  'M22.4,253.4a2.5,2.5,0,0,1,1.2.8,2.7,2.7,0,0,1,.7,1.4,5.2,5.2,0,0,1,.5,2.4.9.9,0,0,1,.1.5,14.7,14.7,0,0,0-9.5,2.9,3.1,3.1,0,0,1-.5-1,6,6,0,0,1-.6-2.7,3.3,3.3,0,0,1,0-1.4,3.6,3.6,0,0,1,.8-1.2,2,2,0,0,1,.8-1.2,2.7,2.7,0,0,1,1.4-.7,5.9,5.9,0,0,1,2.3-.5,2.2,2.2,0,0,1,1.6,0A2.4,2.4,0,0,1,22.4,253.4Z',
  'M37.2,270.9c-2.2,2.8-1.3,7.3,2.2,10l.4.3-.2.3a4.8,4.8,0,0,1-1.7,1.2,5.5,5.5,0,0,1-1.7,1.3,5.5,5.5,0,0,1-2.2.2,8.3,8.3,0,0,1-4.1-.7,3.6,3.6,0,0,1-1.9-.8,4.8,4.8,0,0,1-1.2-1.6c0-.1-.1-.1-.1-.2a3.8,3.8,0,0,1-1.4-3.7,7.3,7.3,0,0,1,.6-4.1,5,5,0,0,1,.8-2,3.6,3.6,0,0,1,1.8-1.2,3.7,3.7,0,0,1,1.7-1.3,4.6,4.6,0,0,1,2.3-.2,7.6,7.6,0,0,1,3.6.6,3.5,3.5,0,0,1,2.2.8Z',
  'M12,279.4a3.2,3.2,0,0,1-1,1,4.1,4.1,0,0,1-1.6.3,6.5,6.5,0,0,1-2.4-.1H6.5a14.9,14.9,0,0,0-.5-10l1.1-.2a5.4,5.4,0,0,1,2.8.1,3.1,3.1,0,0,1,1.3.3,2.6,2.6,0,0,1,1,1.1,2.4,2.4,0,0,1,1,1,2.1,2.1,0,0,1,.3,1.5,3.9,3.9,0,0,1-.1,2.4,2.2,2.2,0,0,1-.3,1.6A5.2,5.2,0,0,1,12,279.4Z',
  'M48.3,287.5a1.9,1.9,0,0,1,.7-1.2,2.9,2.9,0,0,1,1.4-.8,6,6,0,0,1,2.3-.6h.6a14.8,14.8,0,0,0,3.2,9.5l-.9.4a5.7,5.7,0,0,1-2.8.8,3.3,3.3,0,0,1-1.4,0,2.5,2.5,0,0,1-1.2-.8A1.9,1.9,0,0,1,49,294a2.6,2.6,0,0,1-.8-1.3,7.1,7.1,0,0,1-.6-2.4,2.9,2.9,0,0,1-.1-1.6A2.5,2.5,0,0,1,48.3,287.5Z',
  'M28.7,302.8a2.9,2.9,0,0,1-1.3-.5,2.1,2.1,0,0,1-1-1.3,5.3,5.3,0,0,1-1.1-2.1c-.1-.2-.2-.3-.2-.5a14.6,14.6,0,0,0,8.6-5,2.7,2.7,0,0,1,.7.8,5.8,5.8,0,0,1,1.2,2.5,3.2,3.2,0,0,1,.3,1.4,1.9,1.9,0,0,1-.5,1.3,2.3,2.3,0,0,1-.4,1.4,2.3,2.3,0,0,1-1.2,1,4.4,4.4,0,0,1-2.2,1,2,2,0,0,1-1.5.4A3.3,3.3,0,0,1,28.7,302.8Z',
  'M54.1,314.8a1.9,1.9,0,0,1-.6,1.3,2.2,2.2,0,0,1-1.3.8,6.3,6.3,0,0,1-2.3.8l-.5.2a14.9,14.9,0,0,0-4-9.2,2.4,2.4,0,0,1,.9-.6,6.2,6.2,0,0,1,2.7-.9,2.2,2.2,0,0,1,1.4-.1,2.7,2.7,0,0,1,1.2.6,3.1,3.1,0,0,1,1.3.6,3.8,3.8,0,0,1,.9,1.3,6.3,6.3,0,0,1,.8,2.3,2.9,2.9,0,0,1,.2,1.6A3.3,3.3,0,0,1,54.1,314.8Z',
  'M5.2,333.3a2.6,2.6,0,0,0,1.3.6,2.3,2.3,0,0,0,1.6-.3,5.8,5.8,0,0,0,2.2-.8l.5-.3a14.8,14.8,0,0,1-3-9.5,1.8,1.8,0,0,0-1,.2,4.9,4.9,0,0,0-2.6,1A2,2,0,0,0,3,325a2.1,2.1,0,0,0-.5,1.3,3.1,3.1,0,0,0-.6,1.3,2.4,2.4,0,0,0,.2,1.5,5,5,0,0,0,.9,2.3,2,2,0,0,0,.9,1.3A3.1,3.1,0,0,0,5.2,333.3Z',
  'M77.7,344.2c-3.4.9-5.3,5.1-4.2,9.4,0,.1.1.3.1.4h-.2a4.1,4.1,0,0,1-2.2,0,3.7,3.7,0,0,1-2.1,0,4.2,4.2,0,0,1-1.8-1.1,7.9,7.9,0,0,1-3-3,4,4,0,0,1-1-1.8,3.4,3.4,0,0,1,0-2V346a3.7,3.7,0,0,1,0-2.1,3.9,3.9,0,0,1,1.1-1.7,7.9,7.9,0,0,1,3-3,3.7,3.7,0,0,1,1.8-1.1,3.7,3.7,0,0,1,2.1.1,4.1,4.1,0,0,1,2.2,0,3.5,3.5,0,0,1,1.9,1.2A8.1,8.1,0,0,1,78,342a4.6,4.6,0,0,1,1.3,2A4.4,4.4,0,0,0,77.7,344.2Z',
  'M76.7,364.1a3.1,3.1,0,0,1,1.3-.6,3.8,3.8,0,0,1,1.6.3,5,5,0,0,1,2.3.9l.5.2a14.5,14.5,0,0,0-3.1,9.5h-1a5,5,0,0,1-2.6-1.1,2,2,0,0,1-1.2-.8,2.4,2.4,0,0,1-.5-1.3,3.1,3.1,0,0,1-.6-1.3,2.9,2.9,0,0,1,.2-1.6,8.5,8.5,0,0,1,.9-2.2,3,3,0,0,1,.9-1.3A3.8,3.8,0,0,1,76.7,364.1Z',
  'M92.1,402.9a2.2,2.2,0,0,1,1.3.4,2.5,2.5,0,0,1,1.1,1.2,4.6,4.6,0,0,1,1.3,2c.1.2.2.3.2.5a14.9,14.9,0,0,0-8.2,5.7,2.7,2.7,0,0,1-.7-.8,4.5,4.5,0,0,1-1.4-2.4,2.3,2.3,0,0,1-.4-1.4,1.9,1.9,0,0,1,.3-1.3,2.3,2.3,0,0,1,.4-1.4,2,2,0,0,1,1.1-1.1,5.5,5.5,0,0,1,2.1-1.2,2.5,2.5,0,0,1,1.5-.5A3.2,3.2,0,0,1,92.1,402.9Z',
  'M66.5,414a2.3,2.3,0,0,1-1.4-.4,2.6,2.6,0,0,1-1-1.3,4.1,4.1,0,0,1-1-2.1l-.3-.5a15.6,15.6,0,0,0,8.7-5,1.6,1.6,0,0,1,.6.8,4.7,4.7,0,0,1,1.3,2.5,3.2,3.2,0,0,1,.3,1.4,2.9,2.9,0,0,1-.5,1.3,2.4,2.4,0,0,1-.5,1.4,2.9,2.9,0,0,1-1.2,1,5.1,5.1,0,0,1-2.1,1,2.9,2.9,0,0,1-1.6.4A2.1,2.1,0,0,1,66.5,414Z',
  'M85.3,430.2a3.2,3.2,0,0,1-.2,1.4,2.7,2.7,0,0,1-1,1.3,5.2,5.2,0,0,1-1.9,1.4l-.5.3a15.1,15.1,0,0,0-6.6-7.5l.7-.8a5.4,5.4,0,0,1,2.2-1.7,2.1,2.1,0,0,1,1.3-.5,2.2,2.2,0,0,1,1.4.2,3.2,3.2,0,0,1,1.4.1,4,4,0,0,1,1.2,1,7.5,7.5,0,0,1,1.5,2,2.6,2.6,0,0,1,.7,1.4A3.2,3.2,0,0,1,85.3,430.2Z',
  'M38.2,446.1a2.3,2.3,0,0,1,.4-1.4,2.8,2.8,0,0,1,1.3-.9,6.2,6.2,0,0,1,2.2-1l.5-.2a15,15,0,0,0,4.7,8.8,1.6,1.6,0,0,1-.8.6,4.8,4.8,0,0,1-2.6,1.2,2.2,2.2,0,0,1-1.4.2,2.1,2.1,0,0,1-1.3-.5,2.1,2.1,0,0,1-1.3-.5,2.3,2.3,0,0,1-1-1.2A6,6,0,0,1,38,449a2.9,2.9,0,0,1-.4-1.6A2.6,2.6,0,0,1,38.2,446.1Z',
  'M127.6,446.7a2.4,2.4,0,0,0-1.4-.5,2,2,0,0,0-1.5.4,4.4,4.4,0,0,0-2.2,1l-.5.3a15.4,15.4,0,0,1,3.8,9.2,1.8,1.8,0,0,0,1-.2,5.8,5.8,0,0,0,2.5-1.2,3.1,3.1,0,0,0,1.1-.9,2.5,2.5,0,0,0,.4-1.4,2.1,2.1,0,0,0,.5-1.3,2.4,2.4,0,0,0-.3-1.5,5.7,5.7,0,0,0-1.1-2.2,2.6,2.6,0,0,0-1-1.3A2.2,2.2,0,0,0,127.6,446.7Z',
  'M65.6,451.2a2,2,0,0,1,1.2.8,2,2,0,0,1,.6,1.5,4.5,4.5,0,0,1,.4,2.3,1.3,1.3,0,0,1,.1.6,14.3,14.3,0,0,0-9.7,2.4,2.1,2.1,0,0,1-.4-1,5.2,5.2,0,0,1-.5-2.8,2.3,2.3,0,0,1,.1-1.4,4.4,4.4,0,0,1,.8-1.1,3.6,3.6,0,0,1,.8-1.2,2.4,2.4,0,0,1,1.5-.6,4.7,4.7,0,0,1,2.4-.4,2.9,2.9,0,0,1,1.6,0A3.1,3.1,0,0,1,65.6,451.2Z',
  'M49.1,468.4a3.1,3.1,0,0,0-1.3.3,2.8,2.8,0,0,0-1.1,1.3,5.3,5.3,0,0,0-1.1,2.1l-.3.5a14.5,14.5,0,0,1,8.5,5.3l.7-.8a5,5,0,0,0,1.3-2.5,2.3,2.3,0,0,0,.4-1.4,2.9,2.9,0,0,0-.5-1.3,2.3,2.3,0,0,0-.4-1.4,2.6,2.6,0,0,0-1.1-1,6.5,6.5,0,0,0-2.2-1.1,2.7,2.7,0,0,0-1.5-.5A2.4,2.4,0,0,0,49.1,468.4Z',
  'M121,477.7c3.5.6,5.7,4.7,4.9,9,0,.2-.1.3-.1.5h.3a5,5,0,0,0,2.1-.2,3.8,3.8,0,0,0,2.1-.2,4.4,4.4,0,0,0,1.8-1.2,8.4,8.4,0,0,0,2.7-3.2,5.1,5.1,0,0,0,.9-1.8,4.4,4.4,0,0,0-.2-2v-.3a3.5,3.5,0,0,0-.2-2,3.9,3.9,0,0,0-1.1-1.7A8.4,8.4,0,0,0,131,472a5,5,0,0,0-1.9-1,5.3,5.3,0,0,0-2.2.2,3.9,3.9,0,0,0-2.1.2,4.5,4.5,0,0,0-1.9,1.4,8.7,8.7,0,0,0-2.3,2.7,3.6,3.6,0,0,0-1.1,2.1Z',
  'M57.2,495.3a2.4,2.4,0,0,0,.2,1.4,4.3,4.3,0,0,0,1.1,1.2,6.9,6.9,0,0,0,2,1.3l.5.3a14.6,14.6,0,0,1,6.2-7.8,1.6,1.6,0,0,0-.7-.7,5.5,5.5,0,0,0-2.3-1.7,2.8,2.8,0,0,0-1.3-.4,2.2,2.2,0,0,0-1.4.2,2.2,2.2,0,0,0-1.4.3,2.9,2.9,0,0,0-1.2,1,5.6,5.6,0,0,0-1.4,2,3.4,3.4,0,0,0-.6,1.5A3.2,3.2,0,0,0,57.2,495.3Z',
  'M77.8,515.3a2.1,2.1,0,0,0-1.1.8,2.3,2.3,0,0,0-.5,1.5,6.7,6.7,0,0,0-.4,2.4v.6a15.2,15.2,0,0,1,9.8,1.9,3,3,0,0,0,.4-1,5.2,5.2,0,0,0,.3-2.8,2.4,2.4,0,0,0-.2-1.4,4.4,4.4,0,0,0-.8-1.1,3.1,3.1,0,0,0-.9-1.1,2.5,2.5,0,0,0-1.5-.5,6.1,6.1,0,0,0-2.4-.4,3.1,3.1,0,0,0-1.6.2A3.1,3.1,0,0,0,77.8,515.3Z',
  'M65.3,529.4a2.6,2.6,0,0,0-1.3-.6,3,3,0,0,0-1.6.2,4.7,4.7,0,0,0-2.2.9l-.5.2a15,15,0,0,1,2.8,9.6l1-.2a5.2,5.2,0,0,0,2.7-1,2.1,2.1,0,0,0,1.1-.8,2.2,2.2,0,0,0,.6-1.3,2.6,2.6,0,0,0,.4-2.8,6.3,6.3,0,0,0-.8-2.3,2.8,2.8,0,0,0-.9-1.3A3.1,3.1,0,0,0,65.3,529.4Z',
  'M78.3,554.1a1.9,1.9,0,0,0,1.3.5,2.9,2.9,0,0,0,1.6-.3,5.8,5.8,0,0,0,2.1-1l.5-.3a15.2,15.2,0,0,1-3.6-9.3l-1,.2a5.8,5.8,0,0,0-2.5,1.2,2.4,2.4,0,0,0-1.1.9,2.4,2.4,0,0,0-.5,1.3,2.1,2.1,0,0,0-.5,1.3,4.1,4.1,0,0,0,.3,1.6,5.1,5.1,0,0,0,1.1,2.2,2.2,2.2,0,0,0,.9,1.2A2.4,2.4,0,0,0,78.3,554.1Z',
  'M80.4,574c-3.5-.7-5.7-4.8-4.8-9.2,0-.1.1-.3.1-.4h-.2a4.1,4.1,0,0,0-2.2.2,3,3,0,0,0-2.1.1,3.6,3.6,0,0,0-1.8,1.2,7.4,7.4,0,0,0-2.7,3.1,3.9,3.9,0,0,0-1,1.9,3.5,3.5,0,0,0,.2,2v.2a3.4,3.4,0,0,0,.1,2,3.4,3.4,0,0,0,1.1,1.7,7.9,7.9,0,0,0,3.2,2.8,4.4,4.4,0,0,0,1.9,1,3.7,3.7,0,0,0,2.1-.2,3.9,3.9,0,0,0,4-1.5,6.9,6.9,0,0,0,2.4-2.7,3.7,3.7,0,0,0,1.2-2Z',
  'M58.5,623.9a3.7,3.7,0,0,0,1.4-.4,2.9,2.9,0,0,0,1-1.2,5.3,5.3,0,0,0,1.1-2.1l.3-.4a13.8,13.8,0,0,1-8-5.5l-.7.8a5.1,5.1,0,0,0-1.3,2.4,3.3,3.3,0,0,0-.4,1.4,2.8,2.8,0,0,0,.4,1.3,3.5,3.5,0,0,0,.3,1.4,3.5,3.5,0,0,0,1.1,1,7.2,7.2,0,0,0,2.1,1.2,2.3,2.3,0,0,0,1.4.4A2.5,2.5,0,0,0,58.5,623.9Z',
  'M25.3,632a2.1,2.1,0,0,0-1.3-.5,2.7,2.7,0,0,0-1.6.4,6.2,6.2,0,0,0-2.2,1l-.5.3a14.8,14.8,0,0,1,3.8,9.2,1.6,1.6,0,0,0,1-.2A5.8,5.8,0,0,0,27,641a2.4,2.4,0,0,0,1.1-.9,3.9,3.9,0,0,0,.5-1.4,2.8,2.8,0,0,0,.4-1.3,2.4,2.4,0,0,0-.3-1.5,4.6,4.6,0,0,0-1.1-2.2,2.6,2.6,0,0,0-1-1.3A2,2,0,0,0,25.3,632Z',
  'M39.5,638.7a2.2,2.2,0,0,0,.1,1.4,2.5,2.5,0,0,0,.9,1.3,8.5,8.5,0,0,0,1.7,1.6l.4.3a14.7,14.7,0,0,1,6.9-7l-.6-.8a4.8,4.8,0,0,0-2.1-1.9,2.3,2.3,0,0,0-1.2-.6,2.8,2.8,0,0,0-1.3.1,3.2,3.2,0,0,0-1.4.1,2.7,2.7,0,0,0-1.2.9,5.2,5.2,0,0,0-1.5,1.8,2.9,2.9,0,0,0-.8,1.4A3.2,3.2,0,0,0,39.5,638.7Z',
  'M83.7,657.1a2.3,2.3,0,0,0-.4-1.4,2.6,2.6,0,0,0-1.1-1,5.3,5.3,0,0,0-2.1-1.1l-.5-.2a15.1,15.1,0,0,1-5,8.4,2.7,2.7,0,0,0,.8.7,5.1,5.1,0,0,0,2.4,1.3,2.8,2.8,0,0,0,1.3.3,2.9,2.9,0,0,0,1.3-.5,2.2,2.2,0,0,0,1.3-.4,2.9,2.9,0,0,0,1-1.2,5.1,5.1,0,0,0,1-2.1,2.5,2.5,0,0,0,.5-1.5A2.5,2.5,0,0,0,83.7,657.1Z',
  'M57.1,660.5a2.9,2.9,0,0,0-1.2.7,3.4,3.4,0,0,0-.7,1.5,5.9,5.9,0,0,0-.5,2.3.7.7,0,0,0-.1.5,14.2,14.2,0,0,1,9.1,2.9,4.1,4.1,0,0,0,.5-.9,4.9,4.9,0,0,0,.6-2.7,3.3,3.3,0,0,0,0-1.4,2.4,2.4,0,0,0-.7-1.2,3.7,3.7,0,0,0-.7-1.2,2.7,2.7,0,0,0-1.4-.7,5.4,5.4,0,0,0-2.2-.5,2.2,2.2,0,0,0-1.6,0A2.6,2.6,0,0,0,57.1,660.5Z',
  'M18.7,663c3.5.6,5.7,4.7,4.9,9a.7.7,0,0,1-.1.5h.3a4.9,4.9,0,0,0,2.1-.2,4.4,4.4,0,0,0,3.9-1.4,8.4,8.4,0,0,0,2.7-3.2,3.1,3.1,0,0,0,.9-1.8,3.4,3.4,0,0,0-.2-2v-.3a4.6,4.6,0,0,0-.1-2,5.2,5.2,0,0,0-1.2-1.7,8.4,8.4,0,0,0-3.2-2.7,3.4,3.4,0,0,0-1.9-.9,3.7,3.7,0,0,0-2.1.1,4.2,4.2,0,0,0-2.2.2,3.7,3.7,0,0,0-1.8,1.4,7.3,7.3,0,0,0-2.4,2.8,3.1,3.1,0,0,0-1.1,2Z',
  'M80.3,684.9a2,2,0,0,0-.8,1.2,2.9,2.9,0,0,0-.1,1.6,5.1,5.1,0,0,0,.4,2.4,1.1,1.1,0,0,0,.1.5,14.8,14.8,0,0,1,9.9-1,1.7,1.7,0,0,0,.1-1,5.6,5.6,0,0,0-.5-2.8,2.4,2.4,0,0,0-.5-1.3,4.2,4.2,0,0,0-1.2-.8,2.1,2.1,0,0,0-1.1-.8,3,3,0,0,0-1.6-.1,5.1,5.1,0,0,0-2.4.4,2.4,2.4,0,0,0-1.5.6A2.8,2.8,0,0,0,80.3,684.9Z',
  'M105.1,685.9a3.1,3.1,0,0,0,1.3.6,2.9,2.9,0,0,0,1.6-.2,4.7,4.7,0,0,0,2.2-.9l.5-.2a15.7,15.7,0,0,1-2.9-9.5,1.7,1.7,0,0,0-1,.1,4.9,4.9,0,0,0-2.6,1,2.5,2.5,0,0,0-1.2.8,3.8,3.8,0,0,0-.6,1.3,3.1,3.1,0,0,0-.6,1.3,2.9,2.9,0,0,0,.3,1.6,5.8,5.8,0,0,0,.8,2.2,4.2,4.2,0,0,0,.9,1.4A2.1,2.1,0,0,0,105.1,685.9Z',
  'M105.8,705.9c-3.5-1-5.3-5.2-4.1-9.5,0-.1.1-.3.1-.4h-.2a4.1,4.1,0,0,0-2.2,0,3.7,3.7,0,0,0-2.1,0,2.9,2.9,0,0,0-1.8,1,7.2,7.2,0,0,0-3,3,3.4,3.4,0,0,0-1.1,1.7,3.4,3.4,0,0,0,0,2v.3a3.4,3.4,0,0,0,0,2,4,4,0,0,0,1,1.8,8.3,8.3,0,0,0,2.9,3,4,4,0,0,0,1.9,1.1,3.7,3.7,0,0,0,2.1,0,4.2,4.2,0,0,0,4.1-1.2,8.8,8.8,0,0,0,2.6-2.5,3.9,3.9,0,0,0,1.3-2Z',
  'M104,728.7a5,5,0,0,0,.5-1.3,2.1,2.1,0,0,0-.3-1.6,4.3,4.3,0,0,0-.9-2.2l-.3-.5a14.7,14.7,0,0,1-9.4,3.2,1.6,1.6,0,0,0,.2,1,4.7,4.7,0,0,0,1.1,2.6,2.5,2.5,0,0,0,.8,1.2,3.4,3.4,0,0,0,1.4.5,1.9,1.9,0,0,0,1.3.5,2.6,2.6,0,0,0,1.5-.2,5.5,5.5,0,0,0,2.2-1,2.4,2.4,0,0,0,1.3-.9A3.8,3.8,0,0,0,104,728.7Z',
  'M114.7,743.6a3.2,3.2,0,0,0-1.4-.2,2.2,2.2,0,0,0-1.4.8,7,7,0,0,0-1.9,1.5l-.4.4a15.2,15.2,0,0,1,6,8.1l.9-.5a5.2,5.2,0,0,0,2.1-1.8,2,2,0,0,0,.8-1.2,20,20,0,0,1,.3-2.8,3.1,3.1,0,0,0-.7-1.4,5.5,5.5,0,0,0-1.6-1.8,2.6,2.6,0,0,0-2.7-1.1Z',
  'M112.2,771.6a3.2,3.2,0,0,0-.3,1.4,3.9,3.9,0,0,0,.6,1.5,6.3,6.3,0,0,0,1.3,2l.3.4a15.3,15.3,0,0,1,8.7-4.9,1.7,1.7,0,0,0-.3-1,4.6,4.6,0,0,0-1.6-2.3,3.2,3.2,0,0,0-1-1,2.4,2.4,0,0,0-1.4-.2,1.7,1.7,0,0,0-1.4-.3,2.5,2.5,0,0,0-1.5.5,6.3,6.3,0,0,0-2,1.3,4.3,4.3,0,0,0-1.1,1.2A3.2,3.2,0,0,0,112.2,771.6Z',
  'M144,780.4c-3.5.5-6.9-2.6-7.6-7v-.5h-.3a4.1,4.1,0,0,0-1.9.8,4,4,0,0,0-2,.9,3.7,3.7,0,0,0-1.3,1.7,8.8,8.8,0,0,0-1.5,3.9,3.4,3.4,0,0,0-.2,2,3.3,3.3,0,0,0,.8,1.9v.2a3,3,0,0,0,.8,1.8,4,4,0,0,0,1.6,1.3,7.8,7.8,0,0,0,3.9,1.5,3.9,3.9,0,0,0,2.1.3,3.7,3.7,0,0,0,2-.9,3.3,3.3,0,0,0,1.9-.8,4.3,4.3,0,0,0,1.4-1.9,8.3,8.3,0,0,0,1.3-3.4,3.8,3.8,0,0,0,.4-2.4Z',
  'M124,797.8a2.3,2.3,0,0,0-1.4.4,3.2,3.2,0,0,0-1.1,1.2,5.3,5.3,0,0,0-1.1,2.1l-.3.5a14.6,14.6,0,0,1,8.4,5.4,2.7,2.7,0,0,0,.7-.8,5.3,5.3,0,0,0,1.4-2.4,3.2,3.2,0,0,0,.3-1.4,2.3,2.3,0,0,0-.4-1.4,3.1,3.1,0,0,0-.3-1.3A2.5,2.5,0,0,0,129,799a5.3,5.3,0,0,0-2.1-1.1,3,3,0,0,0-1.6-.5A2.8,2.8,0,0,0,124,797.8Z',
  'M151.6,801.9a3.3,3.3,0,0,0,0-1.4,3,3,0,0,0-.9-1.3,5.5,5.5,0,0,0-1.8-1.6c-.1-.2-.3-.3-.4-.4a14.6,14.6,0,0,1-7.3,6.8,2.4,2.4,0,0,0,.6.9,6.1,6.1,0,0,0,2,1.9,3.3,3.3,0,0,0,1.3.7,2.3,2.3,0,0,0,1.4-.1,3.3,3.3,0,0,0,1.4,0,3,3,0,0,0,1.3-.9,4,4,0,0,0,1.6-1.8,3,3,0,0,0,.9-1.3A3.8,3.8,0,0,0,151.6,801.9Z',
  'M116,814.9a3.2,3.2,0,0,0-1.4.3,2.5,2.5,0,0,0-1.1,1.2,3.9,3.9,0,0,0-1.3,2l-.3.5a15.1,15.1,0,0,1,8.1,5.9,4.6,4.6,0,0,0,.7-.7,6.1,6.1,0,0,0,1.5-2.4,2.3,2.3,0,0,0,.4-1.4,2.4,2.4,0,0,0-.3-1.4,3.1,3.1,0,0,0-.3-1.3,2.5,2.5,0,0,0-1.1-1.2,4.5,4.5,0,0,0-2-1.2,2.2,2.2,0,0,0-1.5-.6A3.2,3.2,0,0,0,116,814.9Z',
  'M155,841.3c-3.3,1.5-7.4-.4-9.4-4.4a.8.8,0,0,0-.2-.4h-.2a3.6,3.6,0,0,0-1.6,1.4,3.6,3.6,0,0,0-1.6,1.4,3.1,3.1,0,0,0-.7,2,7.4,7.4,0,0,0-.3,4.2,3.6,3.6,0,0,0,.3,2,5,5,0,0,0,1.4,1.5c0,.1.1.1.1.2a4.7,4.7,0,0,0,1.3,1.5,4.8,4.8,0,0,0,2,.7,7.5,7.5,0,0,0,4.2.3,3.9,3.9,0,0,0,2.1-.4A4.3,4.3,0,0,0,154,850a3.6,3.6,0,0,0,1.6-1.4,3.6,3.6,0,0,0,.7-2.2,8.8,8.8,0,0,0,.2-3.6,3.4,3.4,0,0,0-.3-2.4A4.7,4.7,0,0,1,155,841.3Z',
  'M168.6,859.6a2.5,2.5,0,0,0-.4-1.4,2.8,2.8,0,0,0-1.3-.9,5.7,5.7,0,0,0-2.2-1.1l-.5-.2a14.7,14.7,0,0,1-4.9,8.7,1.6,1.6,0,0,0,.8.6,5.8,5.8,0,0,0,2.6,1.3,1.9,1.9,0,0,0,1.3.2,2.4,2.4,0,0,0,1.4-.5,2.2,2.2,0,0,0,1.3-.4,2.8,2.8,0,0,0,1-1.2,4.9,4.9,0,0,0,1-2.2,2.3,2.3,0,0,0,.4-1.6A2.1,2.1,0,0,0,168.6,859.6Z',
  'M141,863.9a2.8,2.8,0,0,0-1.2.8,2.5,2.5,0,0,0-.6,1.4,5.2,5.2,0,0,0-.5,2.4.9.9,0,0,0-.1.5,15.2,15.2,0,0,1,9.6,2.7,4.1,4.1,0,0,0,.5-.9,5.4,5.4,0,0,0,.5-2.8,3.3,3.3,0,0,0,0-1.4,3.6,3.6,0,0,0-.8-1.2,4.2,4.2,0,0,0-.8-1.2,2.5,2.5,0,0,0-1.4-.6,5.2,5.2,0,0,0-2.4-.5,2.2,2.2,0,0,0-1.6,0A2.5,2.5,0,0,0,141,863.9Z',
  'M185.1,888.6a1.9,1.9,0,0,0,1.3-.6,2.2,2.2,0,0,0,.8-1.3,4.7,4.7,0,0,0,.9-2.2,1.3,1.3,0,0,0,.1-.6,15,15,0,0,1-9.1-4.1,4.1,4.1,0,0,0-.5.9,4.6,4.6,0,0,0-1,2.6,3.2,3.2,0,0,0-.2,1.4,3.1,3.1,0,0,0,.6,1.3,3.1,3.1,0,0,0,.6,1.3,3.8,3.8,0,0,0,1.3.9,6.3,6.3,0,0,0,2.3.8,2.9,2.9,0,0,0,1.6.2A3.1,3.1,0,0,0,185.1,888.6Z',
  'M168,906.6a1.8,1.8,0,0,0,.3,1.4,2,2,0,0,0,1.1,1.1,5.7,5.7,0,0,0,2.1,1.3l.4.2a15.1,15.1,0,0,1,5.9-8.1,2.7,2.7,0,0,0-.8-.7,4.9,4.9,0,0,0-2.4-1.5,2.8,2.8,0,0,0-1.3-.4,3.2,3.2,0,0,0-1.4.3,3.5,3.5,0,0,0-1.4.3,3.8,3.8,0,0,0-1.1,1.1,5.5,5.5,0,0,0-1.2,2.1,2,2,0,0,0-.6,1.5A2.3,2.3,0,0,0,168,906.6Z',
  'M216.4,917.7a3.1,3.1,0,0,0-.6-1.3,2.9,2.9,0,0,0-1.4-.8,6.3,6.3,0,0,0-2.3-.8h-.5a14.7,14.7,0,0,1-3.8,9.2,2.4,2.4,0,0,0,.9.6,6.2,6.2,0,0,0,2.7.9,3.2,3.2,0,0,0,1.4.1,2.4,2.4,0,0,0,1.2-.7,2.6,2.6,0,0,0,1.3-.6,2.6,2.6,0,0,0,.8-1.3,4.9,4.9,0,0,0,.8-2.3,3,3,0,0,0,.2-1.6A2.7,2.7,0,0,0,216.4,917.7Z',
  'M189.6,925.5a3.1,3.1,0,0,0-1.1.9,3,3,0,0,0-.5,1.6,4.4,4.4,0,0,0-.1,2.3,1.3,1.3,0,0,0-.1.6,14.6,14.6,0,0,1,9.9,1.4,1.7,1.7,0,0,0,.3-1,4.4,4.4,0,0,0,.2-2.8,2.4,2.4,0,0,0-.2-1.4,2.2,2.2,0,0,0-.9-1,2.1,2.1,0,0,0-1-1.1,2.4,2.4,0,0,0-1.4-.5,6.9,6.9,0,0,0-2.5-.2,2.1,2.1,0,0,0-1.5.3A2,2,0,0,0,189.6,925.5Z',
  'M133,944.9c3.5.6,5.7,4.7,4.9,9a4.3,4.3,0,0,1-.1.5h.3a5,5,0,0,0,2.1-.2,3.8,3.8,0,0,0,2.1-.2,4.4,4.4,0,0,0,1.8-1.2,8.4,8.4,0,0,0,2.7-3.2,5.1,5.1,0,0,0,.9-1.8,4.4,4.4,0,0,0-.2-2v-.3a3.5,3.5,0,0,0-.2-2,3.9,3.9,0,0,0-1.1-1.7,8.4,8.4,0,0,0-3.2-2.7,3.7,3.7,0,0,0-1.9-.9,4.1,4.1,0,0,0-2.2.1,3.8,3.8,0,0,0-2.1.2,4.5,4.5,0,0,0-1.9,1.4,7.1,7.1,0,0,0-2.3,2.8,3.7,3.7,0,0,0-1.2,2Z',
  'M180.9,950.8a2.1,2.1,0,0,0-1.3-.5,2.2,2.2,0,0,0-1.6.4,4.9,4.9,0,0,0-2.2,1l-.4.3a14.4,14.4,0,0,1,3.7,9.2l1-.2a5.8,5.8,0,0,0,2.6-1.3,1.8,1.8,0,0,0,1-.9,2.4,2.4,0,0,0,.5-1.3,5.8,5.8,0,0,0,.5-1.4,2.8,2.8,0,0,0-.4-1.5,4.3,4.3,0,0,0-1.1-2.1,2.6,2.6,0,0,0-1-1.3A2,2,0,0,0,180.9,950.8Z',
  'M135.3,973.8a2.4,2.4,0,0,0,.5,1.4,2.9,2.9,0,0,0,1.2,1,6.2,6.2,0,0,0,2.2,1l.5.2a15.2,15.2,0,0,1,4.9-8.7,2.7,2.7,0,0,0-.8-.7,5.7,5.7,0,0,0-2.6-1.2,1.9,1.9,0,0,0-1.3-.2,2.4,2.4,0,0,0-1.4.5,2.2,2.2,0,0,0-1.3.4,2.8,2.8,0,0,0-1,1.2,6.2,6.2,0,0,0-1,2.2,2.5,2.5,0,0,0,.1,2.9Z',
  'M171.6,977.3a2.6,2.6,0,0,0-.6,1.3,3,3,0,0,0,.2,1.6,4.5,4.5,0,0,0,.8,2.2.8.8,0,0,0,.2.5,14.5,14.5,0,0,1,9.6-2.6,3.1,3.1,0,0,0-.1-1,6.2,6.2,0,0,0-.9-2.7,3.2,3.2,0,0,0-.8-1.2,3.1,3.1,0,0,0-1.3-.6,2.6,2.6,0,0,0-2.8-.4,4.9,4.9,0,0,0-2.3.8,2.9,2.9,0,0,0-1.4.8A3.1,3.1,0,0,0,171.6,977.3Z',
  'M161.4,1027.9a3.3,3.3,0,0,0,.4,1.3,3.1,3.1,0,0,0,1.3,1,4.4,4.4,0,0,0,2.2,1l.5.2a15,15,0,0,1,4.9-8.7c-.3-.2-.5-.5-.8-.6a5.2,5.2,0,0,0-2.6-1.2,3.2,3.2,0,0,0-1.4-.3,2.9,2.9,0,0,0-1.3.5,2.7,2.7,0,0,0-1.4.5,2.7,2.7,0,0,0-.9,1.2,4.4,4.4,0,0,0-1,2.2,2,2,0,0,0-.4,1.5A2.4,2.4,0,0,0,161.4,1027.9Z',
  'M193.3,1041.5a2,2,0,0,0,.4-1.3,2.4,2.4,0,0,0-.5-1.6,5.3,5.3,0,0,0-1.1-2.1l-.3-.5a15.2,15.2,0,0,1-9,4.3,2.4,2.4,0,0,0,.2.9,5.6,5.6,0,0,0,1.4,2.5,1.9,1.9,0,0,0,1,1,2.2,2.2,0,0,0,1.3.4,2.3,2.3,0,0,0,1.4.4,2,2,0,0,0,1.5-.4,4.5,4.5,0,0,0,2.1-1.2,2.3,2.3,0,0,0,1.2-1A2.3,2.3,0,0,0,193.3,1041.5Z',
  'M163.5,1050.8a3.1,3.1,0,0,0,.6,1.3,2.9,2.9,0,0,0,1.4.8,6.2,6.2,0,0,0,2.3.7h.6a14.6,14.6,0,0,1,3.5-9.4l-.9-.5a5,5,0,0,0-2.7-.8,2.2,2.2,0,0,0-1.4,0,2.4,2.4,0,0,0-1.3.7,2,2,0,0,0-1.2.6,2.9,2.9,0,0,0-.8,1.4,6.2,6.2,0,0,0-.7,2.3,2.9,2.9,0,0,0-.1,1.6A2.4,2.4,0,0,0,163.5,1050.8Z',
]
