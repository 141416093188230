export default [
  'M165.8,7.8c3.6.6,5.8,4.6,5.1,9a4.3,4.3,0,0,0-.1.5h.3a3.8,3.8,0,0,0,2.1-.2,3.8,3.8,0,0,0,2.1-.2,3.5,3.5,0,0,0,1.7-1.2,7.3,7.3,0,0,0,2.7-3.3,3.1,3.1,0,0,0,.9-1.8,4.4,4.4,0,0,0-.2-2V8.3a4.7,4.7,0,0,0-.2-2A4.8,4.8,0,0,0,179,4.7,7.6,7.6,0,0,0,175.8,2a3.7,3.7,0,0,0-2-.9,3.1,3.1,0,0,0-2.1.2,3.8,3.8,0,0,0-2.1.1,4,4,0,0,0-1.9,1.5,9.1,9.1,0,0,0-2.3,2.8,4.2,4.2,0,0,0-1.1,2.1Z',
  'M193.3,26a2.5,2.5,0,0,0,.8-1.2,2.9,2.9,0,0,0-.1-1.6,6.2,6.2,0,0,0-.7-2.3c0-.2-.1-.3-.1-.5a15.5,15.5,0,0,1-9.8,2,1.7,1.7,0,0,0,0,1,5.3,5.3,0,0,0,.8,2.7,1.9,1.9,0,0,0,.7,1.2,2.1,2.1,0,0,0,1.2.7,2.1,2.1,0,0,0,1.2.7,2.2,2.2,0,0,0,1.6,0,6.2,6.2,0,0,0,2.3-.7,2.3,2.3,0,0,0,1.4-.7A2.4,2.4,0,0,0,193.3,26Z',
  'M106.6,268.2c3.6.6,5.8,4.6,5.1,9,0,.1-.1.3-.1.4h.3a5,5,0,0,0,2.1-.2,3.8,3.8,0,0,0,2.1-.2,3.7,3.7,0,0,0,1.7-1.3,7.6,7.6,0,0,0,2.7-3.2,3.1,3.1,0,0,0,.9-1.8,5,5,0,0,0-.2-2.1v-.2a4.7,4.7,0,0,0-.2-2,5.2,5.2,0,0,0-1.2-1.7,8.4,8.4,0,0,0-3.2-2.7,6.1,6.1,0,0,0-2-.9,3.9,3.9,0,0,0-2.1.3,3.8,3.8,0,0,0-2.1.1,4.5,4.5,0,0,0-1.9,1.4,10.4,10.4,0,0,0-2.3,2.8,5.3,5.3,0,0,0-1.1,2.1Z',
  'M168.6,27.6a3.4,3.4,0,0,0-1.4-.5,3.7,3.7,0,0,0-1.5.4,6.5,6.5,0,0,0-2.2,1.1l-.4.3a14.9,14.9,0,0,1,3.8,9.2,1.7,1.7,0,0,0,1-.3,4.9,4.9,0,0,0,2.5-1.2,2.1,2.1,0,0,0,1.1-1,2,2,0,0,0,.4-1.3,2.4,2.4,0,0,0,.5-1.4,2.8,2.8,0,0,0-.4-1.5,4.7,4.7,0,0,0-1.1-2.1,2.1,2.1,0,0,0-1-1.3A3.3,3.3,0,0,0,168.6,27.6Z',
  'M147.1,53.8a2.6,2.6,0,0,0,1,1.1,2,2,0,0,0,1.5.4,4,4,0,0,0,2.4.2.4.4,0,0,0,.5,0,15.3,15.3,0,0,1,1.4-9.9l-.9-.3a5.7,5.7,0,0,0-2.9-.2,3.1,3.1,0,0,0-1.3.3,2,2,0,0,0-1.1.9,2.6,2.6,0,0,0-1.1,1,1.8,1.8,0,0,0-.4,1.4,4.2,4.2,0,0,0-.2,2.5,2.6,2.6,0,0,0,.2,1.5A2.5,2.5,0,0,0,147.1,53.8Z',
  'M116.3,74.4a3.8,3.8,0,0,0-.6-1.3,2.5,2.5,0,0,0-1.4-.8,4.3,4.3,0,0,0-2.2-.9h-.5a15.6,15.6,0,0,1-4.1,9.1l.8.5a5.2,5.2,0,0,0,2.7,1,3.2,3.2,0,0,0,1.4.2,2.4,2.4,0,0,0,1.3-.7,2.4,2.4,0,0,0,1.3-.5,2.6,2.6,0,0,0,.8-1.3,5,5,0,0,0,.9-2.3,2.9,2.9,0,0,0,.2-1.6A3.1,3.1,0,0,0,116.3,74.4Z',
  'M82,90.7a2.1,2.1,0,0,0-1.1.8,2.2,2.2,0,0,0-.6,1.5,4.6,4.6,0,0,0-.5,2.3v.6a15,15,0,0,1,9.7,2.3,3.6,3.6,0,0,0,.4-.9,5.4,5.4,0,0,0,.5-2.8,3.6,3.6,0,0,0-.2-1.4,1.5,1.5,0,0,0-.8-1.1,2.6,2.6,0,0,0-2.2-1.8,5.1,5.1,0,0,0-2.4-.4,2.2,2.2,0,0,0-1.6,0A2.7,2.7,0,0,0,82,90.7Z',
  'M134.3,91.5a3.5,3.5,0,0,0,1.4-.3,2.5,2.5,0,0,0,1.1-1.2,5.3,5.3,0,0,0,1.3-2c.1-.2.2-.3.2-.5a14.9,14.9,0,0,1-8.1-5.8,2.3,2.3,0,0,0-.7.7,6.1,6.1,0,0,0-1.5,2.4,2.3,2.3,0,0,0-.4,1.4,2.8,2.8,0,0,0,.3,1.3,3.2,3.2,0,0,0,.3,1.4,2.9,2.9,0,0,0,1.1,1.1,5.7,5.7,0,0,0,2.1,1.3,2.5,2.5,0,0,0,1.5.5A3.2,3.2,0,0,0,134.3,91.5Z',
  'M107.8,101a3.9,3.9,0,0,0,.5,1.4,3,3,0,0,0,1.3.9,4.4,4.4,0,0,0,2.2,1l.5.2a14.6,14.6,0,0,1,4.8-8.7,1.6,1.6,0,0,0-.8-.6,4.8,4.8,0,0,0-2.6-1.2,3.2,3.2,0,0,0-1.4-.3,2.9,2.9,0,0,0-1.3.5,2.1,2.1,0,0,0-1.3.5,2.3,2.3,0,0,0-1,1.2,6.2,6.2,0,0,0-1,2.2,2.9,2.9,0,0,0-.4,1.6A5,5,0,0,0,107.8,101Z',
  'M68.2,108.8c2.3,2.7,1.6,7.3-1.7,10.2l-.4.3.2.2a3.6,3.6,0,0,0,1.8,1.2,5.7,5.7,0,0,0,1.8,1.2,5,5,0,0,0,2.1.1,7.5,7.5,0,0,0,4.1-.9,3.1,3.1,0,0,0,1.8-.9,4.1,4.1,0,0,0,1.2-1.7v-.2a3.4,3.4,0,0,0,1.1-1.7,4.4,4.4,0,0,0,.1-2,7.3,7.3,0,0,0-.9-4.1,3.4,3.4,0,0,0-.9-1.9,3.6,3.6,0,0,0-1.8-1.2,3.5,3.5,0,0,0-1.7-1.2,4.9,4.9,0,0,0-2.4,0,7.1,7.1,0,0,0-3.5.7,3.7,3.7,0,0,0-2.2,1A9.4,9.4,0,0,1,68.2,108.8Z',
  'M41,246.2a5.8,5.8,0,0,0,.5-1.4,2,2,0,0,0-.3-1.5,5.4,5.4,0,0,0-.9-2.2l-.3-.5a14.8,14.8,0,0,1-9.4,3.3,1.8,1.8,0,0,0,.2,1,5.4,5.4,0,0,0,1.1,2.6,2.1,2.1,0,0,0,.8,1.1,2.4,2.4,0,0,0,1.4.5,1.9,1.9,0,0,0,1.3.5,2.5,2.5,0,0,0,1.5-.2,5.5,5.5,0,0,0,2.2-1,2.4,2.4,0,0,0,1.3-.9A3.8,3.8,0,0,0,41,246.2Z',
  'M16.6,251a2.2,2.2,0,0,0-1.4-.2,2.2,2.2,0,0,0-1.5.6,5,5,0,0,0-1.9,1.3l-.5.3a14.8,14.8,0,0,1,5.1,8.7l1-.4a5.3,5.3,0,0,0,2.3-1.6,2.4,2.4,0,0,0,.9-1.1,3.2,3.2,0,0,0,.3-1.4,2.2,2.2,0,0,0,.2-1.4,2.4,2.4,0,0,0-.5-1.4,4.8,4.8,0,0,0-1.4-2,2.5,2.5,0,0,0-1.2-1.1A3.2,3.2,0,0,0,16.6,251Z',
  'M1.2,282.9a3.4,3.4,0,0,0-.2,1.4,2.6,2.6,0,0,0,.7,1.5,5.2,5.2,0,0,0,1.4,1.9l.3.5a14.7,14.7,0,0,1,8.5-5.4,3.1,3.1,0,0,0-.5-1,5.7,5.7,0,0,0-1.6-2.2,3.1,3.1,0,0,0-1.1-.9,2.4,2.4,0,0,0-1.4-.2,2.3,2.3,0,0,0-1.4-.2,2,2,0,0,0-1.5.6,5.2,5.2,0,0,0-1.9,1.4,3.2,3.2,0,0,0-1.1,1.2A3.6,3.6,0,0,0,1.2,282.9Z',
  'M116.3,289.6a2.6,2.6,0,0,0-2.9-.1,5.7,5.7,0,0,0-2.2,1.1l-.5.2a14.9,14.9,0,0,1,3.9,9.3,2.9,2.9,0,0,0,1-.3,5.8,5.8,0,0,0,2.5-1.2,3.5,3.5,0,0,0,1.1-1,2.2,2.2,0,0,0,.4-1.3,2.4,2.4,0,0,0,.5-1.4,2.8,2.8,0,0,0-.4-1.5,4.3,4.3,0,0,0-1.1-2.1,2.6,2.6,0,0,0-1-1.3A2.2,2.2,0,0,0,116.3,289.6Z',
  'M27.7,292a3.4,3.4,0,0,0,1.4-.1,3.2,3.2,0,0,0,1.3-1,7,7,0,0,0,1.5-1.9l.3-.4a15.3,15.3,0,0,1-7.3-6.9l-.8.6a5.5,5.5,0,0,0-1.8,2.2,3.1,3.1,0,0,0-.6,1.3,3.2,3.2,0,0,0,.2,1.4,2.6,2.6,0,0,0,1.1,2.7,6.5,6.5,0,0,0,1.8,1.5,2.6,2.6,0,0,0,1.5.7A2.3,2.3,0,0,0,27.7,292Z',
  'M4.8,305.5c3.6.1,6.4,3.8,6.2,8.2v.5h.3a2.9,2.9,0,0,0,2-.5,3.8,3.8,0,0,0,2.1-.4,4.6,4.6,0,0,0,1.6-1.5,8.4,8.4,0,0,0,2.2-3.6,3.9,3.9,0,0,0,.6-1.9,4.6,4.6,0,0,0-.5-2v-.2a3.5,3.5,0,0,0-.5-2,4,4,0,0,0-1.4-1.5,7.8,7.8,0,0,0-3.6-2.2,4,4,0,0,0-2-.7,3.6,3.6,0,0,0-2,.6,3.8,3.8,0,0,0-2.1.4A4,4,0,0,0,6,300.4a8.1,8.1,0,0,0-1.9,3.1,3.3,3.3,0,0,0-.8,2.2Z',
  'M87.7,315.2a2.6,2.6,0,0,0,1.3.6,4.1,4.1,0,0,0,1.6-.3,4.7,4.7,0,0,0,2.2-.9l.5-.2a15,15,0,0,1-3.1-9.5h-1a5.4,5.4,0,0,0-2.7,1.1,2.8,2.8,0,0,0-1.1.8,2.4,2.4,0,0,0-.5,1.4,2.6,2.6,0,0,0-.4,2.8,5.5,5.5,0,0,0,1,2.2,2.4,2.4,0,0,0,.9,1.4A2.9,2.9,0,0,0,87.7,315.2Z',
  'M34.5,319.9a3.1,3.1,0,0,0,.6-1.3,2.9,2.9,0,0,0-.4-1.6,4.3,4.3,0,0,0-.9-2.2.8.8,0,0,0-.2-.5,15,15,0,0,1-9.5,3.3,3.6,3.6,0,0,0,.2,1,6.6,6.6,0,0,0,1.1,2.6,3.1,3.1,0,0,0,.9,1.1,2.4,2.4,0,0,0,1.3.5,3.1,3.1,0,0,0,1.3.6,4.1,4.1,0,0,0,1.6-.3,6.2,6.2,0,0,0,2.2-1,2,2,0,0,0,1.3-.9A2.4,2.4,0,0,0,34.5,319.9Z',
  'M10.2,324.8a1.7,1.7,0,0,0-1.4-.3,2,2,0,0,0-1.5.6,6.3,6.3,0,0,0-2,1.3l-.4.4a15,15,0,0,1,5.1,8.6l.9-.4a5.3,5.3,0,0,0,2.3-1.6,2.7,2.7,0,0,0,1-1.1,2.2,2.2,0,0,0,.2-1.4,3.2,3.2,0,0,0,.3-1.4,2.5,2.5,0,0,0-.6-1.4,4.6,4.6,0,0,0-1.4-2,2,2,0,0,0-1.1-1.1A2.4,2.4,0,0,0,10.2,324.8Z',
  'M27,347a3.3,3.3,0,0,0,1.4.4,2.5,2.5,0,0,0,1.5-.5,5.5,5.5,0,0,0,2.1-1.2l.5-.3a14.8,14.8,0,0,1-4.4-9,2.9,2.9,0,0,0-1,.3,5.8,5.8,0,0,0-2.4,1.4,2.4,2.4,0,0,0-1,1,2.3,2.3,0,0,0-.4,1.4,2.9,2.9,0,0,0-.4,1.4,2.4,2.4,0,0,0,.5,1.4,4.5,4.5,0,0,0,1.2,2.1,2.5,2.5,0,0,0,1.1,1.2A2.2,2.2,0,0,0,27,347Z',
  'M33.9,384.1c3-2.1,7.4-.8,9.9,2.9a.5.5,0,0,1,.2.4l.3-.2a3.3,3.3,0,0,0,1.3-1.6,4.6,4.6,0,0,0,1.5-1.6,3.9,3.9,0,0,0,.3-2.1,7.5,7.5,0,0,0-.3-4.2,4,4,0,0,0-.7-1.9,4,4,0,0,0-1.5-1.4h-.2a3.4,3.4,0,0,0-1.6-1.3,4.5,4.5,0,0,0-2-.4,8.6,8.6,0,0,0-4.2.4,3.6,3.6,0,0,0-2,.6,3.6,3.6,0,0,0-1.3,1.7,4.1,4.1,0,0,0-1.8,3.9,7.4,7.4,0,0,0,.3,3.6,3.9,3.9,0,0,0,.7,2.3A5.2,5.2,0,0,1,33.9,384.1Z',
  'M49.7,396.3a3.1,3.1,0,0,0-1.3.6,2.9,2.9,0,0,0-.8,1.4,4.9,4.9,0,0,0-.8,2.3.8.8,0,0,0-.2.5,14.8,14.8,0,0,1,9.2,3.9,4.7,4.7,0,0,0,.6-.9,6.2,6.2,0,0,0,.9-2.7,3.2,3.2,0,0,0,.1-1.4,2.3,2.3,0,0,0-.6-1.2,2.4,2.4,0,0,0-.7-1.3,2.2,2.2,0,0,0-1.3-.8,4.5,4.5,0,0,0-2.3-.8,2.5,2.5,0,0,0-1.5-.2A2.6,2.6,0,0,0,49.7,396.3Z',
  'M137,420.5l-1.4-.5a2.6,2.6,0,0,0-1.5.4,5.5,5.5,0,0,0-2.2,1l-.5.3a15.3,15.3,0,0,1,3.7,9.3l.9-.2a5.7,5.7,0,0,0,2.6-1.2,3.1,3.1,0,0,0,1.1-.9,3.9,3.9,0,0,0,.5-1.4,5,5,0,0,0,.5-1.3,2.4,2.4,0,0,0-.4-1.5,5.5,5.5,0,0,0-1-2.2,2.6,2.6,0,0,0-1-1.3A3.5,3.5,0,0,0,137,420.5Z',
  'M94.2,421.1a3.1,3.1,0,0,0,.6-1.3,2.9,2.9,0,0,0-.4-1.6,4.7,4.7,0,0,0-.9-2.2c0-.2-.1-.3-.2-.5a15,15,0,0,1-9.5,3.3,3,3,0,0,0,.2,1,6.6,6.6,0,0,0,1.1,2.6,3.1,3.1,0,0,0,.9,1.1,2.4,2.4,0,0,0,1.3.5,3.1,3.1,0,0,0,1.3.6,4.1,4.1,0,0,0,1.6-.3,4.9,4.9,0,0,0,2.2-1,2,2,0,0,0,1.3-.9A2.1,2.1,0,0,0,94.2,421.1Z',
  'M69.1,433.3a2.3,2.3,0,0,0-.6,1.2,2.2,2.2,0,0,0,.1,1.6,6.3,6.3,0,0,0,.8,2.3c0,.2.1.3.2.5a14.7,14.7,0,0,1,9.7-2.5,3.1,3.1,0,0,0-.1-1,6.2,6.2,0,0,0-.9-2.7,2.5,2.5,0,0,0-.8-1.2,3.1,3.1,0,0,0-1.3-.6,2.7,2.7,0,0,0-1.3-.7,2.6,2.6,0,0,0-1.5.2,4.9,4.9,0,0,0-2.3.8,2.3,2.3,0,0,0-1.4.8A2.6,2.6,0,0,0,69.1,433.3Z',
  'M152.4,443.7a2.5,2.5,0,0,0-1.4.4,2.3,2.3,0,0,0-1,1.2,5.7,5.7,0,0,0-1.1,2.2l-.2.4a14.8,14.8,0,0,1,8.5,5.2,2.7,2.7,0,0,0,.7-.8,5.5,5.5,0,0,0,1.3-2.5,2.8,2.8,0,0,0,.3-1.3,2.4,2.4,0,0,0-.5-1.4,2.5,2.5,0,0,0-.4-1.4,2.9,2.9,0,0,0-1.2-1,5.3,5.3,0,0,0-2.1-1.1,2.9,2.9,0,0,0-1.6-.4A2.1,2.1,0,0,0,152.4,443.7Z',
  'M122.9,444.7a2.2,2.2,0,0,0-.1,1.4,1.7,1.7,0,0,0,.7,1.4,5.5,5.5,0,0,0,1.5,1.9l.4.4a14.7,14.7,0,0,1,8.1-5.8,2.5,2.5,0,0,0-.4-.9,5.5,5.5,0,0,0-1.8-2.2,2,2,0,0,0-1.2-.8,3.2,3.2,0,0,0-1.4-.2,3.2,3.2,0,0,0-1.4-.1,2.7,2.7,0,0,0-1.4.7A5.5,5.5,0,0,0,124,442a2.7,2.7,0,0,0-1,1.3A3.2,3.2,0,0,0,122.9,444.7Z',
  'M160.8,470.5a3.9,3.9,0,0,0,.3,1.4,2,2,0,0,0,1.1,1.1,4.8,4.8,0,0,0,2,1.4l.4.3a14.8,14.8,0,0,1,6.2-7.9,2.7,2.7,0,0,0-.7-.8,4.9,4.9,0,0,0-2.4-1.5,1.9,1.9,0,0,0-1.3-.5,3.2,3.2,0,0,0-1.4.3,2.4,2.4,0,0,0-1.4.2,2.9,2.9,0,0,0-1.1,1.1,4.8,4.8,0,0,0-1.4,2,3.4,3.4,0,0,0-.6,1.5A3.2,3.2,0,0,0,160.8,470.5Z',
  'M181.7,490.2a3.1,3.1,0,0,0-1.1.9,2.7,2.7,0,0,0-.5,1.5,5,5,0,0,0-.3,2.4v.5a15.1,15.1,0,0,1,9.8,1.8,1.6,1.6,0,0,0,.3-.9,5.5,5.5,0,0,0,.4-2.8,3.2,3.2,0,0,0-.2-1.4,2.5,2.5,0,0,0-.9-1.1,2.7,2.7,0,0,0-.9-1.2,3,3,0,0,0-1.5-.5,4.8,4.8,0,0,0-2.4-.3,3,3,0,0,0-1.6.2A3.1,3.1,0,0,0,181.7,490.2Z',
  'M187.2,504.9a3.3,3.3,0,0,0-1.4.4,3.1,3.1,0,0,0-1,1.3,5.3,5.3,0,0,0-1.1,2.1c-.1.2-.2.3-.2.5a14.8,14.8,0,0,1,8.5,5.2,2.7,2.7,0,0,0,.7-.8,6,6,0,0,0,1.3-2.5,3.2,3.2,0,0,0,.3-1.4,2.1,2.1,0,0,0-.5-1.3,2.3,2.3,0,0,0-.4-1.4,2.3,2.3,0,0,0-1.2-1,5.3,5.3,0,0,0-2.1-1.1,2.9,2.9,0,0,0-1.6-.4A2.8,2.8,0,0,0,187.2,504.9Z',
  'M213.4,514.4a2.1,2.1,0,0,0,1.3-.5,2,2,0,0,0,.9-1.3,5.5,5.5,0,0,0,1-2.2l.2-.5a15.2,15.2,0,0,1-9-4.5l-.6.8a6.6,6.6,0,0,0-1.1,2.6,6.8,6.8,0,0,0-.2,1.4,3.5,3.5,0,0,0,.6,1.4,2.4,2.4,0,0,0,.5,1.3,3.8,3.8,0,0,0,1.3.9,4.3,4.3,0,0,0,2.2.9,2.2,2.2,0,0,0,1.6.3A3.1,3.1,0,0,0,213.4,514.4Z',
  'M228.4,527.7c-3.1,1.8-7.4.2-9.7-3.5-.1-.2-.2-.3-.2-.5l-.3.2a4.3,4.3,0,0,0-1.5,1.5,5.4,5.4,0,0,0-1.5,1.5,3.9,3.9,0,0,0-.4,2.1,7.5,7.5,0,0,0,0,4.2,3.6,3.6,0,0,0,.6,2,3.2,3.2,0,0,0,1.4,1.4c.1,0,.1.1.2.2a3.5,3.5,0,0,0,1.4,1.3,3.1,3.1,0,0,0,2,.5,7.9,7.9,0,0,0,4.3,0,3.9,3.9,0,0,0,2-.6,5.4,5.4,0,0,0,1.5-1.5,4.6,4.6,0,0,0,1.5-1.6,3.5,3.5,0,0,0,.4-2.2,7.7,7.7,0,0,0,0-3.7,3.8,3.8,0,0,0-.6-2.3A5.2,5.2,0,0,1,228.4,527.7Z',
]
