export default [
  'M10.6,3.2a1.8,1.8,0,0,1,.8,1.1,2.2,2.2,0,0,1,.1,1.6,5,5,0,0,1-.3,2.4c0,.2-.1.3-.1.5A15,15,0,0,0,1.1,8,4.1,4.1,0,0,1,1,6.9a5,5,0,0,1,.5-2.7A1.9,1.9,0,0,1,2,2.9,2.7,2.7,0,0,1,3.2,2a3.1,3.1,0,0,1,1.1-.8,2.9,2.9,0,0,1,1.6-.1,5,5,0,0,1,2.4.3A4.7,4.7,0,0,1,9.8,2,3.6,3.6,0,0,1,10.6,3.2Z',
  'M17.9,24.8c-3.1,1.8-3.8,6.3-1.6,10.2,0,.1.1.2.2.4h-.2a4.2,4.2,0,0,1-2.1.6,4.6,4.6,0,0,1-2,.5,3.7,3.7,0,0,1-2.1-.6,7.3,7.3,0,0,1-3.6-2.1A4,4,0,0,1,5,32.5a4.9,4.9,0,0,1-.5-2v-.2a2.9,2.9,0,0,1-.5-2,3.8,3.8,0,0,1,.5-1.9,8.5,8.5,0,0,1,2.1-3.7,5.4,5.4,0,0,1,1.5-1.5,4.1,4.1,0,0,1,2.1-.5,3.6,3.6,0,0,1,2-.6,4.5,4.5,0,0,1,2.3.7,8.4,8.4,0,0,1,3.1,1.9,3.3,3.3,0,0,1,1.7,1.6Z',
  'M26.7,62.2a2,2,0,0,1,1.1-.9,2.9,2.9,0,0,1,1.6-.1,6.5,6.5,0,0,1,2.4.2h.5a15,15,0,0,0-.5,10,1.7,1.7,0,0,1-1,.1,4.5,4.5,0,0,1-2.8-.3,2.9,2.9,0,0,1-1.3-.5,3.1,3.1,0,0,1-.9-1.1,2.7,2.7,0,0,1-.9-1.2,2,2,0,0,1-.1-1.5,4.9,4.9,0,0,1,.2-2.4,2.2,2.2,0,0,1,.6-1.5A2.4,2.4,0,0,1,26.7,62.2Z',
  'M87.3,70.5a2.6,2.6,0,0,1,.1-1.5,2.3,2.3,0,0,1,1-1.2,5.5,5.5,0,0,1,1.8-1.6l.4-.3a15.5,15.5,0,0,0,7,7.1l-.6.9a5.6,5.6,0,0,1-2.2,1.8,2.3,2.3,0,0,1-1.2.6,3.2,3.2,0,0,1-1.4-.1,3.8,3.8,0,0,1-1.5-.1,2.9,2.9,0,0,1-1.2-1,4.6,4.6,0,0,1-1.6-1.8,2.7,2.7,0,0,1-.7-1.4A2.2,2.2,0,0,1,87.3,70.5Z',
  'M125.1,74a2.2,2.2,0,0,1,1.3.4,2.5,2.5,0,0,1,1.1,1.2,4.7,4.7,0,0,1,1.2,2l.3.5a15.7,15.7,0,0,0-8.4,5.6l-.7-.8a7.2,7.2,0,0,1-1.4-2.4,3.2,3.2,0,0,1-.3-1.4,2.3,2.3,0,0,1,.4-1.4,2.1,2.1,0,0,1,.3-1.3,2.5,2.5,0,0,1,1.2-1.1,4.8,4.8,0,0,1,2.1-1.2,2.5,2.5,0,0,1,1.5-.5A3.3,3.3,0,0,1,125.1,74Z',
  'M51.1,78.7a3.4,3.4,0,0,1-.5,1.4,3,3,0,0,1-1.3.9,5.5,5.5,0,0,1-2.2,1l-.5.2a15,15,0,0,0-4.7-8.8,1.6,1.6,0,0,1,.8-.6,6.2,6.2,0,0,1,2.6-1.2,2.2,2.2,0,0,1,1.4-.2,2.1,2.1,0,0,1,1.3.5,2.1,2.1,0,0,1,1.3.5,2.3,2.3,0,0,1,1,1.2,6.2,6.2,0,0,1,1,2.2,2.9,2.9,0,0,1,.3,1.6A1.9,1.9,0,0,1,51.1,78.7Z',
  'M144.3,86.3c-1.3,3.4.9,7.4,5,9l.5.2v.2a3.7,3.7,0,0,1-1.3,1.7,3.8,3.8,0,0,1-1.3,1.8,4.1,4.1,0,0,1-1.9.8,8.5,8.5,0,0,1-4.2.6,4.7,4.7,0,0,1-2-.2,4,4,0,0,1-1.6-1.2l-.2-.2a3.1,3.1,0,0,1-1.6-1.2,2.9,2.9,0,0,1-.8-1.9,8.5,8.5,0,0,1-.7-4.2,4,4,0,0,1,.3-2.1,3.6,3.6,0,0,1,1.3-1.7,3.8,3.8,0,0,1,1.2-1.7,3.7,3.7,0,0,1,2.2-.8,7.4,7.4,0,0,1,3.6-.5,3.9,3.9,0,0,1,2.4.1A3.5,3.5,0,0,0,144.3,86.3Z',
  'M104.3,92.5a2,2,0,0,1,0,1.5,2.2,2.2,0,0,1-.8,1.3,5.5,5.5,0,0,1-1.7,1.7c-.1.2-.3.3-.4.4a15.3,15.3,0,0,0-7.6-6.6l.6-.9a5.5,5.5,0,0,1,2-2,3.5,3.5,0,0,1,1.2-.7,2.2,2.2,0,0,1,1.4.1,2.6,2.6,0,0,1,1.5-.1,3.8,3.8,0,0,1,1.3.9,5.5,5.5,0,0,1,1.7,1.7,2.2,2.2,0,0,1,.8,1.3A1.7,1.7,0,0,1,104.3,92.5Z',
  'M76.2,92.6a2.2,2.2,0,0,1-1.4.2,2.1,2.1,0,0,1-1.4-.7,4,4,0,0,1-1.9-1.5l-.4-.3A15.3,15.3,0,0,0,76.7,82a1.9,1.9,0,0,1,.9.5,5.5,5.5,0,0,1,2.3,1.7,3.6,3.6,0,0,1,.8,1.2,3.2,3.2,0,0,1,.2,1.4,3.2,3.2,0,0,1,.1,1.4,2.5,2.5,0,0,1-.6,1.4,7,7,0,0,1-1.5,1.9,2.7,2.7,0,0,1-1.3,1A2.2,2.2,0,0,1,76.2,92.6Z',
  'M159.8,99a1.7,1.7,0,0,1,.3-1.4,2,2,0,0,1,1.1-1.1,5.7,5.7,0,0,1,2.1-1.3l.4-.3a14.8,14.8,0,0,0,5.9,8.1,3,3,0,0,1-.8.8,4.9,4.9,0,0,1-2.3,1.4,2.4,2.4,0,0,1-1.4.5,2.3,2.3,0,0,1-1.4-.4,1.9,1.9,0,0,1-1.4-.3,2.9,2.9,0,0,1-1.1-1.1,5.1,5.1,0,0,1-1.2-2,2.4,2.4,0,0,1-.6-1.5A2.3,2.3,0,0,1,159.8,99Z',
  'M228.3,203.5a3.3,3.3,0,0,1,.7,1.3,2.9,2.9,0,0,1-.1,1.6,6.2,6.2,0,0,1-.7,2.3l-.2.5a14.7,14.7,0,0,0-9.8-2.2,3.1,3.1,0,0,1,.1-1,5,5,0,0,1,.8-2.7,2.6,2.6,0,0,1,2-1.9,2.9,2.9,0,0,1,1.2-.7,2.9,2.9,0,0,1,1.6.1,6.2,6.2,0,0,1,2.3.7,2.9,2.9,0,0,1,1.4.8A2.1,2.1,0,0,1,228.3,203.5Z',
  'M206.5,221.1a2.8,2.8,0,0,1-1.3.3,3,3,0,0,1-1.6-.5,4.7,4.7,0,0,1-2-1.2l-.5-.3a15,15,0,0,0,4.5-8.9,1.7,1.7,0,0,1,1,.3,4.9,4.9,0,0,1,2.4,1.4,3.2,3.2,0,0,1,1,1,1.8,1.8,0,0,1,.3,1.4,2.3,2.3,0,0,1,.4,1.4,3,3,0,0,1-.5,1.5,5,5,0,0,1-1.2,2.1,2.9,2.9,0,0,1-1.1,1.1A2.3,2.3,0,0,1,206.5,221.1Z',
  'M232.7,226c-3.4,1.3-4.7,5.7-3.1,9.8l.2.4-.2.2a5.2,5.2,0,0,1-2.1.2,4.1,4.1,0,0,1-2.2.3,3.4,3.4,0,0,1-1.9-.9,7,7,0,0,1-3.3-2.6,3.4,3.4,0,0,1-1.3-1.6,4.6,4.6,0,0,1-.2-2v-.2a3.5,3.5,0,0,1-.3-2,3.7,3.7,0,0,1,.8-1.9,8.5,8.5,0,0,1,2.6-3.3,3.1,3.1,0,0,1,1.7-1.3,3.9,3.9,0,0,1,2.1-.3,5.1,5.1,0,0,1,2.1-.3,5,5,0,0,1,2.2,1,8.9,8.9,0,0,1,2.8,2.3,3.4,3.4,0,0,1,1.5,1.8Z',
  'M234.2,245.9a2.5,2.5,0,0,1,1.2-.8,2.9,2.9,0,0,1,1.6.1,4.6,4.6,0,0,1,2.3.6h.6a15.1,15.1,0,0,0-1.9,9.9h-1a4.4,4.4,0,0,1-2.7-.7,3.1,3.1,0,0,1-1.3-.6,3.3,3.3,0,0,1-.7-1.3,2.4,2.4,0,0,1-.7-1.2,2.6,2.6,0,0,1,0-1.5,5.3,5.3,0,0,1,.6-2.4,2.9,2.9,0,0,1,.8-1.4A1.9,1.9,0,0,1,234.2,245.9Z',
  'M253.5,268.1a2.8,2.8,0,0,1-1.3.3,3,3,0,0,1-1.6-.5,4.7,4.7,0,0,1-2-1.2l-.5-.3a15,15,0,0,0,4.5-8.9,1.7,1.7,0,0,1,1,.3,4.9,4.9,0,0,1,2.4,1.4,3.2,3.2,0,0,1,1,1,1.8,1.8,0,0,1,.3,1.4,2.3,2.3,0,0,1,.4,1.4,3,3,0,0,1-.5,1.5,5,5,0,0,1-1.2,2.1,2.9,2.9,0,0,1-1.1,1.1A2.3,2.3,0,0,1,253.5,268.1Z',
  'M226,272.5a2.7,2.7,0,0,1-1.2.9,2.2,2.2,0,0,1-1.6,0,5.1,5.1,0,0,1-2.4-.4h-.5a14.7,14.7,0,0,0,1-9.9h1.1a5,5,0,0,1,2.7.5,3.1,3.1,0,0,1,1.3.6,2.1,2.1,0,0,1,.8,1.1,2.7,2.7,0,0,1,.9,1.2,2.2,2.2,0,0,1,0,1.6,3.7,3.7,0,0,1-.4,2.3,2,2,0,0,1-.6,1.5A2.1,2.1,0,0,1,226,272.5Z',
  'M279.7,273c-3.4,1.3-4.7,5.7-3.1,9.8l.2.4-.2.2a5.2,5.2,0,0,1-2.1.2,4.1,4.1,0,0,1-2.2.3,3.4,3.4,0,0,1-1.9-.9,7,7,0,0,1-3.3-2.6,3.4,3.4,0,0,1-1.3-1.6,4.6,4.6,0,0,1-.2-2v-.2a3.5,3.5,0,0,1-.3-2,3.7,3.7,0,0,1,.8-1.9,8.5,8.5,0,0,1,2.6-3.3,3.1,3.1,0,0,1,1.7-1.3,3.9,3.9,0,0,1,2.1-.3,5.1,5.1,0,0,1,2.1-.3,5,5,0,0,1,2.2,1,8.9,8.9,0,0,1,2.8,2.3,3.4,3.4,0,0,1,1.5,1.8Z',
  'M281.2,292.9a2.5,2.5,0,0,1,1.2-.8,2.9,2.9,0,0,1,1.6.1,4.6,4.6,0,0,1,2.3.6h.6a15.1,15.1,0,0,0-1.9,9.9h-1a4.4,4.4,0,0,1-2.7-.7,3.1,3.1,0,0,1-1.3-.6,3.3,3.3,0,0,1-.7-1.3,2.4,2.4,0,0,1-.7-1.2,2.6,2.6,0,0,1,0-1.5,5.3,5.3,0,0,1,.6-2.4,2.9,2.9,0,0,1,.8-1.4A1.9,1.9,0,0,1,281.2,292.9Z',
  'M256.7,296.6a2.4,2.4,0,0,1-1-1,3,3,0,0,1-.3-1.6,4.9,4.9,0,0,1,.2-2.4v-.5a14.9,14.9,0,0,0,10-.2,3.6,3.6,0,0,1,.2,1,5.4,5.4,0,0,1-.2,2.8,2.3,2.3,0,0,1-.4,1.4,2.4,2.4,0,0,1-1.1.9,2.4,2.4,0,0,1-1,1,2.6,2.6,0,0,1-1.5.2,5.3,5.3,0,0,1-2.5-.1,2.8,2.8,0,0,1-1.5-.4A3.1,3.1,0,0,1,256.7,296.6Z',
  'M273,319.5a2.7,2.7,0,0,1-1.2.9,2.2,2.2,0,0,1-1.6,0,5.1,5.1,0,0,1-2.4-.4h-.5a14.7,14.7,0,0,0,1-9.9h1.1a5,5,0,0,1,2.7.5,3.1,3.1,0,0,1,1.3.6,2.1,2.1,0,0,1,.8,1.1,2.7,2.7,0,0,1,.9,1.2,2.2,2.2,0,0,1,0,1.6,3.7,3.7,0,0,1-.4,2.3,2,2,0,0,1-.6,1.5A2.1,2.1,0,0,1,273,319.5Z',
  'M279.2,356.7c-3.5-.9-7.2,1.9-8.3,6.1a.9.9,0,0,0-.1.5h-.3a4.2,4.2,0,0,1-1.8-1.1,3.7,3.7,0,0,1-1.9-1.1,4.2,4.2,0,0,1-1.1-1.8,8.1,8.1,0,0,1-1.1-4.1,3.4,3.4,0,0,1,0-2,4,4,0,0,1,1-1.8.3.3,0,0,0,.1-.2,4.2,4.2,0,0,1,1-1.7,4.2,4.2,0,0,1,1.8-1.1,8.1,8.1,0,0,1,4.1-1.1,3.7,3.7,0,0,1,2.1,0,4.2,4.2,0,0,1,1.8,1.1,3.9,3.9,0,0,1,1.9,1,4.4,4.4,0,0,1,1.1,2,7.3,7.3,0,0,1,.9,3.5,4,4,0,0,1,.2,2.4Z',
  'M268.6,373.6a3.6,3.6,0,0,1,1.4.2,2.3,2.3,0,0,1,1.2,1,4.3,4.3,0,0,1,1.5,1.8l.4.5a14.9,14.9,0,0,0-7.4,6.8l-.8-.7a5.8,5.8,0,0,1-1.7-2.1,3.1,3.1,0,0,1-.6-1.3,3.2,3.2,0,0,1,.1-1.4,3.9,3.9,0,0,1,.2-1.5,2.7,2.7,0,0,1,.9-1.2,7,7,0,0,1,1.9-1.5,2.7,2.7,0,0,1,1.4-.7A3.8,3.8,0,0,1,268.6,373.6Z',
  'M235.2,412.2a3.6,3.6,0,0,1-1-1.1,3,3,0,0,1-.2-1.6,4.9,4.9,0,0,1,.1-2.4v-.5a14.9,14.9,0,0,0,10-.2,2.2,2.2,0,0,1,.2,1.1,5.3,5.3,0,0,1-.1,2.8,3.5,3.5,0,0,1-.5,1.3,2.4,2.4,0,0,1-1,1,2.4,2.4,0,0,1-1.1.9,2.7,2.7,0,0,1-1.5.3,4.9,4.9,0,0,1-2.4-.2,2.3,2.3,0,0,1-1.6-.4A2.2,2.2,0,0,1,235.2,412.2Z',
  'M262.9,415a2.7,2.7,0,0,1,1.5,2.5,4.9,4.9,0,0,1,0,2.4v.6a14.6,14.6,0,0,0-9.9.9,1.9,1.9,0,0,1-.3-1,5.3,5.3,0,0,1-.1-2.8,2.3,2.3,0,0,1,.4-1.4,2.2,2.2,0,0,1,.9-1,5.2,5.2,0,0,1,1-1.1,2.7,2.7,0,0,1,1.5-.3,4.9,4.9,0,0,1,2.4-.1,2.2,2.2,0,0,1,1.6.3A1.9,1.9,0,0,1,262.9,415Z',
  'M194.8,426.3a2.7,2.7,0,0,1,1.1-1,3,3,0,0,1,1.6-.2,5,5,0,0,1,2.4.3h.5a14.9,14.9,0,0,0-.2,10,1.7,1.7,0,0,1-1,.1,5.3,5.3,0,0,1-2.8-.2,2.4,2.4,0,0,1-1.4-.5,3.1,3.1,0,0,1-.9-1.1,2.5,2.5,0,0,1-.9-1.1,2.6,2.6,0,0,1-.2-1.5,6.5,6.5,0,0,1,.2-2.4,2.7,2.7,0,0,1,.5-1.5A1.9,1.9,0,0,1,194.8,426.3Z',
  'M243.7,435.5a3.1,3.1,0,0,1-.9,1.1,2,2,0,0,1-1.5.6,4.5,4.5,0,0,1-2.3.3h-.6a14.5,14.5,0,0,0-2.1-9.8l1-.4a6.4,6.4,0,0,1,2.8-.4,3.6,3.6,0,0,1,1.4.2,3.1,3.1,0,0,1,1.1.9,2.1,2.1,0,0,1,1.1.8,2,2,0,0,1,.6,1.4,5,5,0,0,1,.3,2.4,2.2,2.2,0,0,1-.1,1.6A2.5,2.5,0,0,1,243.7,435.5Z',
  'M216.3,444.1a3.1,3.1,0,0,1,.6,1.3,2.9,2.9,0,0,1-.2,1.6,4.9,4.9,0,0,1-.8,2.3l-.2.5a15,15,0,0,0-9.6-2.7,3.4,3.4,0,0,1,.1-1,6.6,6.6,0,0,1,.9-2.7,2.8,2.8,0,0,1,.8-1.1,1.8,1.8,0,0,1,1.3-.6,3.3,3.3,0,0,1,1.3-.7,2.5,2.5,0,0,1,1.5.2,6.3,6.3,0,0,1,2.3.8,2.9,2.9,0,0,1,1.4.8A3.8,3.8,0,0,1,216.3,444.1Z',
  'M188.2,453.3a2.6,2.6,0,0,1,1.5-.1,2.2,2.2,0,0,1,1.3.8,6.9,6.9,0,0,1,1.8,1.6c.2.1.3.3.4.4a14.7,14.7,0,0,0-6.3,7.8l-.9-.5a5.2,5.2,0,0,1-2-1.9,2,2,0,0,1-.8-1.2,1.7,1.7,0,0,1,0-1.4,2.6,2.6,0,0,1-.1-1.5,2.2,2.2,0,0,1,.8-1.3,5.5,5.5,0,0,1,1.6-1.8,3,3,0,0,1,1.3-.9A3.3,3.3,0,0,1,188.2,453.3Z',
  'M189.5,481.4a2.2,2.2,0,0,1,.2,1.4,2.5,2.5,0,0,1-.6,1.4,5.6,5.6,0,0,1-1.4,2c-.1.1-.2.3-.4.4a14.5,14.5,0,0,0-8.4-5.3,1.8,1.8,0,0,1,.4-1,5.7,5.7,0,0,1,1.6-2.2,3.1,3.1,0,0,1,1.1-.9,2.4,2.4,0,0,1,1.4-.2,3.2,3.2,0,0,1,1.4-.3,3.4,3.4,0,0,1,1.5.6,4.5,4.5,0,0,1,1.9,1.5,2.3,2.3,0,0,1,1.1,1.1A3.9,3.9,0,0,1,189.5,481.4Z',
  'M176.6,507a2.1,2.1,0,0,1,1.3.5,2.5,2.5,0,0,1,1.1,1.2,5.5,5.5,0,0,1,1,2.2l.3.5a14.5,14.5,0,0,0-8.7,5,2.4,2.4,0,0,1-.6-.8,5,5,0,0,1-1.3-2.5,3.2,3.2,0,0,1-.3-1.4,2.9,2.9,0,0,1,.5-1.3,2.3,2.3,0,0,1,.4-1.4,3.8,3.8,0,0,1,1.2-1,4.9,4.9,0,0,1,2.2-1,2,2,0,0,1,1.5-.4A3.3,3.3,0,0,1,176.6,507Z',
  'M176.5,522.7a3.3,3.3,0,0,1,1.4,0,2.2,2.2,0,0,1,1.4.8,5.5,5.5,0,0,1,1.8,1.6l.4.4a14.7,14.7,0,0,0-6.3,7.8,1.9,1.9,0,0,1-.9-.5,5.7,5.7,0,0,1-2.1-2,2.8,2.8,0,0,1-.8-2.6,3.2,3.2,0,0,1-.1-1.4,2.9,2.9,0,0,1,.8-1.3,5.8,5.8,0,0,1,1.7-1.8,2.1,2.1,0,0,1,1.3-.9A2.3,2.3,0,0,1,176.5,522.7Z',
  'M155.1,540.6a1.7,1.7,0,0,1-1.4,0,2.5,2.5,0,0,1-1.3-.9,7.3,7.3,0,0,1-1.7-1.8l-.3-.4a15.3,15.3,0,0,0,6.9-7.3l.8.6a4.8,4.8,0,0,1,1.9,2.1,2.9,2.9,0,0,1,.7,1.2,2.6,2.6,0,0,1-.1,1.5,3.3,3.3,0,0,1,0,1.4,3,3,0,0,1-.9,1.3,6.9,6.9,0,0,1-1.8,1.6,2.2,2.2,0,0,1-1.4.8A2.3,2.3,0,0,1,155.1,540.6Z',
  'M145.6,558.2c3.5.7,7-2.3,7.9-6.6v-.5h.3a3.9,3.9,0,0,1,1.9,1,4,4,0,0,1,2,.9,4.2,4.2,0,0,1,1.1,1.8,8,8,0,0,1,1.4,4,4.4,4.4,0,0,1,.2,2,5.1,5.1,0,0,1-.9,1.8v.2a5.1,5.1,0,0,1-.9,1.8,3.8,3.8,0,0,1-1.7,1.2,8,8,0,0,1-4,1.4,3.8,3.8,0,0,1-2.1.1,3.1,3.1,0,0,1-1.9-.9,4,4,0,0,1-2-.9,4.7,4.7,0,0,1-1.2-2,9.8,9.8,0,0,1-1.2-3.4,5,5,0,0,1-.3-2.4Z',
  'M264.6,599.9a3.1,3.1,0,0,1,1.1-.9,2.5,2.5,0,0,1,1.7-.1,6,6,0,0,1,2.3.2h.6a14.4,14.4,0,0,0-.5,10h-1a5.3,5.3,0,0,1-2.8-.3,3.4,3.4,0,0,1-1.4-.5,2.5,2.5,0,0,1-.8-1.2,2,2,0,0,1-.9-1.1,2.6,2.6,0,0,1-.2-1.5,5,5,0,0,1,.3-2.4,2.7,2.7,0,0,1,.5-1.5A3.1,3.1,0,0,1,264.6,599.9Z',
  'M147.9,615.1a2.2,2.2,0,0,1-1.4.1,2.9,2.9,0,0,1-1.4-.8,8,8,0,0,1-1.7-1.5l-.4-.4a14.6,14.6,0,0,0,5.8-7.8l.8.5a4.3,4.3,0,0,1,2.1,1.9,2.4,2.4,0,0,1,.8,1.1,3.4,3.4,0,0,1,.1,1.4,3.2,3.2,0,0,1,.1,1.4,2.1,2.1,0,0,1-.7,1.3,6.5,6.5,0,0,1-1.5,1.8,2.2,2.2,0,0,1-1.2.9A2.3,2.3,0,0,1,147.9,615.1Z',
  'M170.7,622.9a2,2,0,0,1,.4,1.3,2.4,2.4,0,0,1-.4,1.5,5.3,5.3,0,0,1-1.1,2.1l-.3.4a15.2,15.2,0,0,0-8.7-4.4,2.9,2.9,0,0,1,.2-.9,5.3,5.3,0,0,1,1.4-2.4,2.2,2.2,0,0,1,.9-1,2.1,2.1,0,0,1,1.3-.3,2.2,2.2,0,0,1,1.3-.4,2.5,2.5,0,0,1,1.5.5,4.7,4.7,0,0,1,2,1.2,2.3,2.3,0,0,1,1.2,1A3.5,3.5,0,0,1,170.7,622.9Z',
  'M281.1,627c-3.1,1.8-3.9,6.4-1.7,10.2l.3.4h-.3a3.6,3.6,0,0,1-2,.6,4.6,4.6,0,0,1-2.1.5,3.4,3.4,0,0,1-2-.6,6.9,6.9,0,0,1-3.7-2.1,3.2,3.2,0,0,1-1.4-1.4,3.5,3.5,0,0,1-.5-2c-.1-.1-.1-.1-.1-.2a4.6,4.6,0,0,1-.5-2,3.8,3.8,0,0,1,.5-1.9,7.8,7.8,0,0,1,2.2-3.7,3.6,3.6,0,0,1,1.5-1.5,3.5,3.5,0,0,1,2-.5,3.7,3.7,0,0,1,2.1-.6,4.1,4.1,0,0,1,2.2.7,7.1,7.1,0,0,1,3.1,1.9,3.1,3.1,0,0,1,1.8,1.6Z',
  'M161.3,649.2a3.2,3.2,0,0,1,1.4.3,2.5,2.5,0,0,1,1.1,1.2,4.6,4.6,0,0,1,1.3,2,.8.8,0,0,1,.2.4,14.5,14.5,0,0,0-7.7,5.8,4.6,4.6,0,0,1-.7-.7,5.8,5.8,0,0,1-1.5-2.4,2.5,2.5,0,0,1-.4-1.3,2.8,2.8,0,0,1,.3-1.3,2.2,2.2,0,0,1,.3-1.4,2.2,2.2,0,0,1,1-1.1,4.6,4.6,0,0,1,2-1.3,2,2,0,0,1,1.4-.5A2.8,2.8,0,0,1,161.3,649.2Z',
  'M135,654.8a3.3,3.3,0,0,1,0-1.4,2.2,2.2,0,0,1,.8-1.4,4.1,4.1,0,0,1,1.5-1.7l.4-.4a14,14,0,0,0,7.5,6.3,1.9,1.9,0,0,1-.5.9,4.9,4.9,0,0,1-1.8,2,2.4,2.4,0,0,1-1.2.7,1.5,1.5,0,0,1-1.3,0,2.3,2.3,0,0,1-1.4.1,2.2,2.2,0,0,1-1.3-.8,5.2,5.2,0,0,1-1.7-1.6,2.8,2.8,0,0,1-.9-1.3A2.3,2.3,0,0,1,135,654.8Z',
  'M167.8,689.8a2,2,0,0,1,1.2.8,2.2,2.2,0,0,1,.6,1.5,4.5,4.5,0,0,1,.4,2.3,1.3,1.3,0,0,1,.1.6,14.8,14.8,0,0,0-9.7,2.3,1.8,1.8,0,0,1-.4-.9,5.4,5.4,0,0,1-.5-2.8,2.3,2.3,0,0,1,.1-1.4,2.4,2.4,0,0,1,.8-1.1,2.7,2.7,0,0,1,.9-1.2,2,2,0,0,1,1.4-.6,4.9,4.9,0,0,1,2.4-.4,2.2,2.2,0,0,1,1.6.1A2.1,2.1,0,0,1,167.8,689.8Z',
  'M144.7,698.9a2.4,2.4,0,0,1-1,1,4.1,4.1,0,0,1-1.6.3,6.6,6.6,0,0,1-2.4-.1h-.5a14.9,14.9,0,0,0-.4-10l1-.2a5.3,5.3,0,0,1,2.8.1,3.2,3.2,0,0,1,1.4.3,5.2,5.2,0,0,1,1,1.1,2.2,2.2,0,0,1,.9,1,2.1,2.1,0,0,1,.3,1.5,5.3,5.3,0,0,1,0,2.5,2.8,2.8,0,0,1-.4,1.5A2.7,2.7,0,0,1,144.7,698.9Z',
  'M150.7,718c2.9-2.1,3.3-6.7.7-10.3l-.3-.4.3-.2a4.8,4.8,0,0,1,2-.7,4.1,4.1,0,0,1,4.1-.3,7.1,7.1,0,0,1,3.8,1.8,3.7,3.7,0,0,1,1.5,1.3,3.4,3.4,0,0,1,.7,1.9v.2a4.4,4.4,0,0,1,.7,1.9,4.5,4.5,0,0,1-.4,2,7.4,7.4,0,0,1-1.8,3.9,4,4,0,0,1-1.3,1.6,4.8,4.8,0,0,1-2,.7,3.7,3.7,0,0,1-2,.7,3.4,3.4,0,0,1-2.3-.5,7.1,7.1,0,0,1-3.3-1.5,4.5,4.5,0,0,1-1.9-1.5A4.2,4.2,0,0,0,150.7,718Z',
  'M187.5,734.8a3.1,3.1,0,0,1,.9-1.1,3,3,0,0,1,1.5-.5,6.5,6.5,0,0,1,2.4-.2h.6a14.8,14.8,0,0,0,1.5,9.9l-1,.3a5.4,5.4,0,0,1-2.8.2,2.2,2.2,0,0,1-1.4-.2,3.3,3.3,0,0,1-1.1-.9,3.1,3.1,0,0,1-1.1-.9,2.8,2.8,0,0,1-.4-1.5,4,4,0,0,1-.2-2.4,2.2,2.2,0,0,1,.2-1.6A2.4,2.4,0,0,1,187.5,734.8Z',
  'M159.9,738.9a2.4,2.4,0,0,1-.9-1.1,2.3,2.3,0,0,1-.3-1.6,4.9,4.9,0,0,1,.2-2.4v-.5a15.6,15.6,0,0,0,10,0,3,3,0,0,1,.2,1,5.4,5.4,0,0,1-.2,2.8,3.3,3.3,0,0,1-.4,1.3,3.1,3.1,0,0,1-1.1.9,2.7,2.7,0,0,1-1.1,1,2.6,2.6,0,0,1-1.5.2,4.9,4.9,0,0,1-2.4-.1,2.4,2.4,0,0,1-1.6-.5A2.2,2.2,0,0,1,159.9,738.9Z',
  'M209,752.8a2.5,2.5,0,0,1,1.2.8,3,3,0,0,1,.7,1.5,5.9,5.9,0,0,1,.5,2.3,1.3,1.3,0,0,0,.1.6,15,15,0,0,0-9.7,2.6,3.6,3.6,0,0,1-.4-.9,6.4,6.4,0,0,1-.6-2.8,2.3,2.3,0,0,1,.1-1.4,2.5,2.5,0,0,1,.8-1.2,2.1,2.1,0,0,1,.8-1.1,2.7,2.7,0,0,1,1.4-.7,6,6,0,0,1,2.4-.5,4.3,4.3,0,0,1,1.6,0Z',
  'M219.3,779.7a2.4,2.4,0,0,1,1.2-.7,2.9,2.9,0,0,1,1.6.1,4.8,4.8,0,0,1,2.3.7h.5a15.4,15.4,0,0,0-2.1,9.8h-1a5.2,5.2,0,0,1-2.7-.7,3.5,3.5,0,0,1-1.2-.7,42,42,0,0,0-1.4-2.5,2.9,2.9,0,0,1,.1-1.6,4.8,4.8,0,0,1,.7-2.3,2.7,2.7,0,0,1,.7-1.4A2.4,2.4,0,0,1,219.3,779.7Z',
  'M192.8,781.5c2.8-2.1,3-6.7.4-10.3l-.3-.3.2-.2a5,5,0,0,1,2-.8,4.4,4.4,0,0,1,2-.8,3.8,3.8,0,0,1,2.1.4,7.6,7.6,0,0,1,3.8,1.6,4.3,4.3,0,0,1,1.7,1.3,4.4,4.4,0,0,1,.7,1.9c0,.1.1.1.1.2a4,4,0,0,1,.7,1.9,3.4,3.4,0,0,1-.3,2,7.6,7.6,0,0,1-1.7,3.9,3.3,3.3,0,0,1-1.3,1.6,3.9,3.9,0,0,1-2,.8,4.1,4.1,0,0,1-1.9.8,4.5,4.5,0,0,1-2.3-.4,6.7,6.7,0,0,1-3.3-1.5,3.9,3.9,0,0,1-2-1.3A5,5,0,0,0,192.8,781.5Z',
  'M202.6,802.1a1.8,1.8,0,0,1-.9-1,2.2,2.2,0,0,1-.3-1.6,3.9,3.9,0,0,1,.1-2.4c-.1-.1,0-.3,0-.5a14.9,14.9,0,0,0,10-.3,3,3,0,0,1,.2,1,5.3,5.3,0,0,1-.1,2.8,3.3,3.3,0,0,1-.4,1.3,2.6,2.6,0,0,1-1.1,1,3.2,3.2,0,0,1-1,1,4,4,0,0,1-1.5.3,6.5,6.5,0,0,1-2.4-.1,2.9,2.9,0,0,1-1.6-.4A3.6,3.6,0,0,1,202.6,802.1Z',
  'M239.3,802.5a3.2,3.2,0,0,1,1.4-.2,2.2,2.2,0,0,1,1.4.8,4.5,4.5,0,0,1,1.9,1.4l.4.4a15.1,15.1,0,0,0-5.6,8.2,1.9,1.9,0,0,1-.9-.4,5.8,5.8,0,0,1-2.3-1.8,2.4,2.4,0,0,1-.8-1.1,3.2,3.2,0,0,1-.2-1.4,3.2,3.2,0,0,1-.1-1.4,2.2,2.2,0,0,1,.6-1.4,7,7,0,0,1,1.5-1.9,3,3,0,0,1,2.7-1.2Z',
  'M211.2,840.2c3.5.5,6.8-2.8,7.3-7.2a.6.6,0,0,0,.1-.4h.2a4,4,0,0,1,2,.8,3.7,3.7,0,0,1,2,.7,4.1,4.1,0,0,1,1.3,1.7,8.1,8.1,0,0,1,1.7,3.9,3.4,3.4,0,0,1,.3,2,4,4,0,0,1-.7,1.9v.2a4.6,4.6,0,0,1-.8,1.9,4,4,0,0,1-1.6,1.3,8.5,8.5,0,0,1-3.9,1.6,3.4,3.4,0,0,1-2,.4,4,4,0,0,1-2-.8,5,5,0,0,1-2-.8,4.3,4.3,0,0,1-1.4-1.9,8.1,8.1,0,0,1-1.4-3.3,3.7,3.7,0,0,1-.5-2.3A4,4,0,0,0,211.2,840.2Z',
  'M231.8,857a2.7,2.7,0,0,1,1.4.4,2.3,2.3,0,0,1,1.1,1.1,7.2,7.2,0,0,1,1.2,2.1l.3.5a14.8,14.8,0,0,0-8.3,5.7l-.7-.8a5.3,5.3,0,0,1-1.4-2.4,2.3,2.3,0,0,1-.4-1.4,2,2,0,0,1,.4-1.3,1.7,1.7,0,0,1,.3-1.4,2,2,0,0,1,1.1-1.1,4.5,4.5,0,0,1,2.1-1.2,2.5,2.5,0,0,1,1.5-.5A2.2,2.2,0,0,1,231.8,857Z',
  'M204.3,862a3.3,3.3,0,0,1,0-1.4,2,2,0,0,1,.9-1.3,4.6,4.6,0,0,1,1.7-1.7l.3-.4a15.8,15.8,0,0,0,7.6,6.6,4.7,4.7,0,0,1-.6.9,4.1,4.1,0,0,1-2,1.9,2.5,2.5,0,0,1-1.2.8,2.2,2.2,0,0,1-1.4-.1,2.3,2.3,0,0,1-1.4.1,2.5,2.5,0,0,1-1.3-.9,5.8,5.8,0,0,1-1.8-1.7,2.2,2.2,0,0,1-.8-1.3A3.8,3.8,0,0,1,204.3,862Z',
  'M198.3,894.9a3.4,3.4,0,0,1-1.4-.1,2.9,2.9,0,0,1-1.2-1,4.9,4.9,0,0,1-1.6-1.9l-.3-.4a14.7,14.7,0,0,0,7.2-6.9l.8.6a5.5,5.5,0,0,1,1.8,2.2,1.6,1.6,0,0,1,.6,1.3,2.3,2.3,0,0,1-.1,1.4,2.3,2.3,0,0,1-.1,1.4,2.3,2.3,0,0,1-1,1.2,4.6,4.6,0,0,1-1.8,1.6,3.7,3.7,0,0,1-1.4.7A3.8,3.8,0,0,1,198.3,894.9Z',
  'M220.4,906.2a2.2,2.2,0,0,1,.2,1.4,2.6,2.6,0,0,1-.7,1.4,5.8,5.8,0,0,1-1.5,1.9c-.1.2-.3.3-.4.4a14.9,14.9,0,0,0-8.2-5.7,1.9,1.9,0,0,1,.5-.9,4.1,4.1,0,0,1,1.7-2.2,2.7,2.7,0,0,1,1.2-.9,20,20,0,0,0,2.8-.3,3.7,3.7,0,0,1,1.4.7,7,7,0,0,1,1.9,1.5,2.7,2.7,0,0,1,1,1.3A2.2,2.2,0,0,1,220.4,906.2Z',
  'M266.1,930.8c-3.1,1.8-3.9,6.4-1.7,10.2a.8.8,0,0,1,.2.4c0,.1-.1.1-.2.2a4.6,4.6,0,0,1-2,.5,5,5,0,0,1-2.1.5,3.7,3.7,0,0,1-2.1-.6,7,7,0,0,1-3.6-2.1,3.2,3.2,0,0,1-1.4-1.4,3.5,3.5,0,0,1-.5-2c-.1,0-.1-.1-.1-.2a4.2,4.2,0,0,1-.5-2,4.1,4.1,0,0,1,.5-1.9,7.8,7.8,0,0,1,2.2-3.7,4,4,0,0,1,1.4-1.5,5.4,5.4,0,0,1,2.1-.5,3.7,3.7,0,0,1,2.1-.6,4.1,4.1,0,0,1,2.2.7,7.7,7.7,0,0,1,3.1,1.9,3.5,3.5,0,0,1,1.8,1.6Z',
  'M206.2,931.2a2.1,2.1,0,0,1,1.3.5,2.6,2.6,0,0,1,1,1.3,4.3,4.3,0,0,1,.9,2.2.8.8,0,0,1,.2.5,14.8,14.8,0,0,0-8.8,4.6,2.1,2.1,0,0,1-.6-.8,6.8,6.8,0,0,1-1.2-2.6,3.2,3.2,0,0,1-.2-1.4,2.6,2.6,0,0,1,.6-1.3,2.4,2.4,0,0,1,.5-1.4,2.7,2.7,0,0,1,1.2-.9,4.7,4.7,0,0,1,2.2-.9,2.9,2.9,0,0,1,1.6-.4A3.1,3.1,0,0,1,206.2,931.2Z',
  'M178.3,932.6a3.6,3.6,0,0,1,.2-1.4,2.9,2.9,0,0,1,1-1.2,7,7,0,0,1,1.9-1.5l.5-.3a14.3,14.3,0,0,0,6.6,7.5,2.7,2.7,0,0,1-.7.8,4.1,4.1,0,0,1-2.2,1.7,3.1,3.1,0,0,1-1.3.6,3.2,3.2,0,0,1-1.4-.2,2.2,2.2,0,0,1-1.4-.2,2.9,2.9,0,0,1-1.2-1,5.8,5.8,0,0,1-1.5-1.9,3.7,3.7,0,0,1-.7-1.4A3.9,3.9,0,0,1,178.3,932.6Z',
  'M188.6,951.4a2.4,2.4,0,0,1,1.1-.9,2.3,2.3,0,0,1,1.6-.2,5,5,0,0,1,2.4.3h.5a14.9,14.9,0,0,0-.5,10h-1a5.3,5.3,0,0,1-2.8-.4,2.1,2.1,0,0,1-1.3-.5,3.1,3.1,0,0,1-.9-1.1,2.4,2.4,0,0,1-.9-1.1,3,3,0,0,1-.2-1.6,6.6,6.6,0,0,1,.3-2.4,2.4,2.4,0,0,1,.6-1.5A1.8,1.8,0,0,1,188.6,951.4Z',
  'M273.4,958.9a3.3,3.3,0,0,1,0,1.4,2.8,2.8,0,0,1-.9,1.4,5.5,5.5,0,0,1-1.7,1.7l-.4.3a15.1,15.1,0,0,0-7.5-6.6,2.4,2.4,0,0,1,.6-.8,4.3,4.3,0,0,1,2-2,1.9,1.9,0,0,1,1.2-.7,2.2,2.2,0,0,1,1.4,0,3.8,3.8,0,0,1,1.5,0,2.6,2.6,0,0,1,1.3.8,9.1,9.1,0,0,1,1.7,1.7,2.5,2.5,0,0,1,.8,1.4A3.3,3.3,0,0,1,273.4,958.9Z',
  'M206.1,973.4a2.4,2.4,0,0,1,1,1,2.1,2.1,0,0,1,.3,1.5,4.9,4.9,0,0,1,0,2.4v.6a14.9,14.9,0,0,0-10,.6,3,3,0,0,1-.2-1,5.3,5.3,0,0,1,0-2.8,2.2,2.2,0,0,1,.4-1.3,1.9,1.9,0,0,1,1-1,2.6,2.6,0,0,1,1-1.1,4,4,0,0,1,1.5-.3,4.9,4.9,0,0,1,2.4,0,2.9,2.9,0,0,1,2.6,1.4Z',
  'M232.4,1017.7a2.6,2.6,0,0,1,0,1.5,2,2,0,0,1-.9,1.3,4.6,4.6,0,0,1-1.7,1.7l-.4.4a14.5,14.5,0,0,0-7.5-6.7l.6-.8a6.4,6.4,0,0,1,2-2,2.4,2.4,0,0,1,1.2-.7,3.8,3.8,0,0,1,1.5,0,3.3,3.3,0,0,1,1.4,0,3,3,0,0,1,1.3.9,4.6,4.6,0,0,1,1.7,1.7,2.7,2.7,0,0,1,.8,2.7Z',
  'M238,1040.1a2.2,2.2,0,0,1-.2,1.4,2.5,2.5,0,0,1-1.1,1.2,5.2,5.2,0,0,1-1.9,1.4l-.5.3a14.9,14.9,0,0,0-6.4-7.6,1.9,1.9,0,0,1,.7-.8,5.5,5.5,0,0,1,2.3-1.7,2.5,2.5,0,0,1,1.3-.5,3.2,3.2,0,0,1,1.4.2,2.4,2.4,0,0,1,1.4.3,2.3,2.3,0,0,1,1.2,1,5.2,5.2,0,0,1,1.4,1.9,2.5,2.5,0,0,1,.6,1.5A2.2,2.2,0,0,1,238,1040.1Z',
  'M189.9,1020.4a2.4,2.4,0,0,1-.7-1.2,2.2,2.2,0,0,1,0-1.6,6.9,6.9,0,0,1,.5-2.4c.1-.1.1-.3.2-.5a14.8,14.8,0,0,0,9.9,1.7,3.4,3.4,0,0,1-.1,1,5.5,5.5,0,0,1-.6,2.8,2.5,2.5,0,0,1-.7,1.2,2.1,2.1,0,0,1-1.2.7,2.5,2.5,0,0,1-1.2.8,4.3,4.3,0,0,1-1.6,0,4.6,4.6,0,0,1-2.3-.6,3.4,3.4,0,0,1-1.5-.7A2.9,2.9,0,0,1,189.9,1020.4Z',
  'M206.8,1041.2a2.7,2.7,0,0,1-.9-1.2,2.2,2.2,0,0,1,0-1.6,4.1,4.1,0,0,1,.4-2.4,1.1,1.1,0,0,1,.1-.5,14.7,14.7,0,0,0,9.9,1,3.4,3.4,0,0,1,.1,1,5.4,5.4,0,0,1-.5,2.8,3.1,3.1,0,0,1-.6,1.3,2.8,2.8,0,0,1-1.1.8,2,2,0,0,1-1.2.8,2.6,2.6,0,0,1-1.5.1,4.7,4.7,0,0,1-2.4-.4,2,2,0,0,1-1.5-.6A2.1,2.1,0,0,1,206.8,1041.2Z',
]
