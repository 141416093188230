export default [
  'M96.4,6.1c3.2,1.5,4.3,6,2.4,10-.1.2-.2.3-.2.4l.2.2a3.9,3.9,0,0,0,2.1.3,3.8,3.8,0,0,0,2.1.5,4.4,4.4,0,0,0,2-.8,7.9,7.9,0,0,0,3.5-2.3,4.2,4.2,0,0,0,1.3-1.6,4.5,4.5,0,0,0,.4-2v-.2a3.4,3.4,0,0,0,.4-2,4,4,0,0,0-.7-1.9,7.1,7.1,0,0,0-2.4-3.5,3.6,3.6,0,0,0-1.6-1.4,4.9,4.9,0,0,0-2.1-.4,4.6,4.6,0,0,0-2.1-.4,4,4,0,0,0-2.1.9,6.9,6.9,0,0,0-3,2,4.6,4.6,0,0,0-1.7,1.7Z',
  'M93.6,25.9a2,2,0,0,0-1.2-.8,2.9,2.9,0,0,0-1.6-.1,4.6,4.6,0,0,0-2.3.5h-.6a14.8,14.8,0,0,1,1.3,9.9h1a5.1,5.1,0,0,0,2.7-.6,1.8,1.8,0,0,0,1.3-.6,2,2,0,0,0,.8-1.2,2.1,2.1,0,0,0,.8-1.1,2.9,2.9,0,0,0,.1-1.6,5.2,5.2,0,0,0-.5-2.4,2.6,2.6,0,0,0-1.8-2.2Z',
  'M117.8,31.2a3.6,3.6,0,0,0,1.1-1,2.3,2.3,0,0,0,.3-1.6,6.5,6.5,0,0,0,.1-2.4.9.9,0,0,0-.1-.5,14.7,14.7,0,0,1-9.9-.8,1.9,1.9,0,0,0-.3,1,6.6,6.6,0,0,0,0,2.8,3.2,3.2,0,0,0,.3,1.4,2.7,2.7,0,0,0,1.1,1,2.2,2.2,0,0,0,.9,1,2.9,2.9,0,0,0,1.6.4,4.9,4.9,0,0,0,2.4,0,2.1,2.1,0,0,0,1.5-.3A2.4,2.4,0,0,0,117.8,31.2Z',
  'M72.5,70.6a2.4,2.4,0,0,0-.2-1.4A2.5,2.5,0,0,0,71.2,68a5.2,5.2,0,0,0-1.9-1.4l-.5-.3a14.9,14.9,0,0,1-6.4,7.6,2.7,2.7,0,0,0,.7.8,5.8,5.8,0,0,0,2.3,1.7,2.5,2.5,0,0,0,1.3.5,3.4,3.4,0,0,0,1.4-.2,3.6,3.6,0,0,0,1.4-.2,2.3,2.3,0,0,0,1.1-1.1,4,4,0,0,0,1.5-1.9,2.5,2.5,0,0,0,.6-1.5A2.2,2.2,0,0,0,72.5,70.6Z',
  'M109.2,76.3a3.8,3.8,0,0,0,.6,1.3,2.5,2.5,0,0,0,1.4.8,4.2,4.2,0,0,0,2.2.8l.5.2a15,15,0,0,1,4.1-9.1,2.4,2.4,0,0,0-.9-.6,5.9,5.9,0,0,0-2.6-1,3.3,3.3,0,0,0-1.4-.1,2.6,2.6,0,0,0-1.3.6,3.1,3.1,0,0,0-1.3.6,3.8,3.8,0,0,0-.9,1.3,6.3,6.3,0,0,0-.8,2.3,2.9,2.9,0,0,0-.2,1.6A3.1,3.1,0,0,0,109.2,76.3Z',
  'M85.2,92h1.4a2.9,2.9,0,0,0,1.4-.8,4.4,4.4,0,0,0,1.7-1.6.8.8,0,0,0,.4-.4,14.9,14.9,0,0,1-6.2-7.8l-.9.5a6.4,6.4,0,0,0-2.1,1.9,3.6,3.6,0,0,0-.8,1.2,3.3,3.3,0,0,0,0,1.4,2.3,2.3,0,0,0-.1,1.4,2.2,2.2,0,0,0,.8,1.4A5.5,5.5,0,0,0,82.4,91a3,3,0,0,0,1.3.9A2.6,2.6,0,0,0,85.2,92Z',
  'M65.4,224.1a2.7,2.7,0,0,0-1.2-.9,2.2,2.2,0,0,0-1.6,0,6.7,6.7,0,0,0-2.4.4l-.5.2a15.4,15.4,0,0,1,1.2,9.9,1.7,1.7,0,0,0,1,0,5.4,5.4,0,0,0,2.8-.5,3.8,3.8,0,0,0,1.3-.6,3.6,3.6,0,0,0,.8-1.2,3.6,3.6,0,0,0,.8-1.2,2,2,0,0,0,0-1.5,5.1,5.1,0,0,0-.4-2.4,3.4,3.4,0,0,0-.7-1.5A2.6,2.6,0,0,0,65.4,224.1Z',
  'M30.9,240.9a2,2,0,0,0,1.1.9,2.2,2.2,0,0,0,1.6.1,4.4,4.4,0,0,0,2.3-.2h.6a14.9,14.9,0,0,1-.4-10h-1a7.2,7.2,0,0,0-2.9.3,3.5,3.5,0,0,0-1.3.5,2.1,2.1,0,0,0-.8,1.1,3.5,3.5,0,0,0-1,1.1,2.9,2.9,0,0,0-.1,1.6,6.5,6.5,0,0,0,.2,2.4,3,3,0,0,0,.5,1.5A2.7,2.7,0,0,0,30.9,240.9Z',
  'M10,261.8a2,2,0,0,0-.6,1.3,2.5,2.5,0,0,0,.2,1.5,6.3,6.3,0,0,0,.8,2.3l.3.5a14.3,14.3,0,0,1,9.5-2.9,3.1,3.1,0,0,0-.1-1,5.2,5.2,0,0,0-1-2.7,2.8,2.8,0,0,0-.8-1.1,3.8,3.8,0,0,0-1.3-.6,3.1,3.1,0,0,0-1.3-.6,2.9,2.9,0,0,0-1.6.2,4.7,4.7,0,0,0-2.2.9,2.4,2.4,0,0,0-1.4.9A1.9,1.9,0,0,0,10,261.8Z',
  'M32.9,277.7a3.2,3.2,0,0,0,1.4.3,2.6,2.6,0,0,0,1.5-.7,6.3,6.3,0,0,0,2-1.3l.4-.3a14.8,14.8,0,0,1-5.1-8.6,2.9,2.9,0,0,0-1,.3,5.5,5.5,0,0,0-2.3,1.7,2.2,2.2,0,0,0-.9,1,2.4,2.4,0,0,0-.2,1.4,1.7,1.7,0,0,0-.3,1.4,2,2,0,0,0,.6,1.5,6.3,6.3,0,0,0,1.3,2,2.9,2.9,0,0,0,1.2,1A2.5,2.5,0,0,0,32.9,277.7Z',
  'M7.2,284.4c3.4,1.1,5.1,5.4,3.8,9.7a.8.8,0,0,0-.2.4H11a5.5,5.5,0,0,0,2.2.1,3.7,3.7,0,0,0,2.1.1,3.5,3.5,0,0,0,1.9-1,8.3,8.3,0,0,0,3.1-2.8,3.9,3.9,0,0,0,1.1-1.7,3.5,3.5,0,0,0,.1-2V287a3.4,3.4,0,0,0,.1-2,3.1,3.1,0,0,0-.9-1.8,7,7,0,0,0-2.8-3.1,3.6,3.6,0,0,0-1.8-1.2,5,5,0,0,0-2.1-.1,4.1,4.1,0,0,0-2.2-.1,4.3,4.3,0,0,0-2,1.1,8.1,8.1,0,0,0-2.7,2.5,4,4,0,0,0-1.4,1.9Z',
  'M7.1,304.4a2.3,2.3,0,0,0-1.2-.6,2.1,2.1,0,0,0-1.6.2,4.8,4.8,0,0,0-2.3.7l-.5.2A15.3,15.3,0,0,1,4,314.6H5a6.2,6.2,0,0,0,2.7-.9,3.2,3.2,0,0,0,1.2-.8,3.1,3.1,0,0,0,.6-1.3,2.4,2.4,0,0,0,.7-1.2,2.9,2.9,0,0,0-.2-1.6,4.9,4.9,0,0,0-.8-2.3,2.9,2.9,0,0,0-.8-1.4A3.1,3.1,0,0,0,7.1,304.4Z',
  'M31.9,306.4a4.4,4.4,0,0,0,.8-1.1,2.3,2.3,0,0,0,.2-1.6,5,5,0,0,0-.3-2.4,4.3,4.3,0,0,0-.1-.5,14.9,14.9,0,0,1-10,.5,4.1,4.1,0,0,0-.1,1.1,5.5,5.5,0,0,0,.4,2.8,2.4,2.4,0,0,0,.5,1.3,2.1,2.1,0,0,0,1.1.8,3.1,3.1,0,0,0,1.1.9,3.1,3.1,0,0,0,1.6.2,5,5,0,0,0,2.4-.3,3.2,3.2,0,0,0,1.5-.6A3.1,3.1,0,0,0,31.9,306.4Z',
  'M17.3,330.4a2.5,2.5,0,0,0,1.2.8,2.9,2.9,0,0,0,1.6-.1,4.6,4.6,0,0,0,2.3-.6h.5a14.8,14.8,0,0,1-1.7-9.9H20.1a5.3,5.3,0,0,0-2.7.6,2.9,2.9,0,0,0-1.2.7,2.5,2.5,0,0,0-.7,1.2,2.8,2.8,0,0,0-.8,2.8,5.3,5.3,0,0,0,.6,2.4,2.7,2.7,0,0,0,.7,1.4A2.4,2.4,0,0,0,17.3,330.4Z',
  'M13.7,368c3.4-1.2,7.3,1.3,8.7,5.5.1.1.1.3.2.4h.2a3.2,3.2,0,0,0,1.8-1.2,4.2,4.2,0,0,0,1.8-1.1,4,4,0,0,0,.9-2,7.9,7.9,0,0,0,.8-4.1,3.5,3.5,0,0,0-.1-2,3.4,3.4,0,0,0-1.1-1.7c0-.1-.1-.1-.1-.2a4.8,4.8,0,0,0-1.2-1.7,5.1,5.1,0,0,0-1.8-.9,8.4,8.4,0,0,0-4.1-.8,5.5,5.5,0,0,0-2.2.1,4.3,4.3,0,0,0-1.7,1.2,3.6,3.6,0,0,0-1.8,1.2,3.7,3.7,0,0,0-1,2.1,8.6,8.6,0,0,0-.7,3.6,3.7,3.7,0,0,0,.1,2.3A5.2,5.2,0,0,1,13.7,368Z',
  'M25.5,384.1a2.4,2.4,0,0,0-1.4.2,2,2,0,0,0-1.1,1.1,4.8,4.8,0,0,0-1.4,2l-.3.4a15.2,15.2,0,0,1,7.8,6.3,2.3,2.3,0,0,0,.7-.7,4.6,4.6,0,0,0,1.6-2.3,2.1,2.1,0,0,0,.5-1.3,2.2,2.2,0,0,0-.2-1.4,2.2,2.2,0,0,0-.3-1.4,2.9,2.9,0,0,0-1-1.2,4.8,4.8,0,0,0-2-1.4,3.4,3.4,0,0,0-1.5-.6A3.5,3.5,0,0,0,25.5,384.1Z',
  'M10.1,416.9a2.2,2.2,0,0,0-.9,1,2.4,2.4,0,0,0-.3,1.6,4.9,4.9,0,0,0,.1,2.4v.6a14.9,14.9,0,0,1,10,.2,1.8,1.8,0,0,0,.2-1,5.3,5.3,0,0,0-.1-2.8,3.3,3.3,0,0,0-.4-1.4,3.1,3.1,0,0,0-1.1-.9,2.4,2.4,0,0,0-1-1,3,3,0,0,0-1.6-.3,6.5,6.5,0,0,0-2.4.1,2.8,2.8,0,0,0-1.5.4A3.5,3.5,0,0,0,10.1,416.9Z',
  'M37.6,412.1a3.1,3.1,0,0,0,.9-1.1,3,3,0,0,0,.1-1.6,5,5,0,0,0-.3-2.4,1.3,1.3,0,0,0-.1-.6,14.1,14.1,0,0,1-9.9.6,2.1,2.1,0,0,0-.1,1.1,4.2,4.2,0,0,0,.3,2.7,3.4,3.4,0,0,0,.5,1.4,2.5,2.5,0,0,0,1.2.8,2,2,0,0,0,1.1.9,2.6,2.6,0,0,0,1.5.2,6.4,6.4,0,0,0,2.4-.4,2,2,0,0,0,1.5-.5A3.1,3.1,0,0,0,37.6,412.1Z',
  'M78.9,423.2a3.6,3.6,0,0,0-1.2-.8,2.9,2.9,0,0,0-1.6-.1,5.9,5.9,0,0,0-2.3.4h-.6a15.1,15.1,0,0,1,1,10h1a5.5,5.5,0,0,0,2.8-.4,3.8,3.8,0,0,0,1.3-.6,2.5,2.5,0,0,0,.8-1.2,1.8,1.8,0,0,0,.8-1.1,2.9,2.9,0,0,0,.1-1.6,5.1,5.1,0,0,0-.4-2.4,2,2,0,0,0-.6-1.4A3.1,3.1,0,0,0,78.9,423.2Z',
  'M30.8,435.9a2.4,2.4,0,0,0,.9,1.1,3,3,0,0,0,1.6.5,6.3,6.3,0,0,0,2.4.2h.5a14.7,14.7,0,0,1,1.4-9.9,1.7,1.7,0,0,0-1-.3,5.3,5.3,0,0,0-2.8-.2,2.3,2.3,0,0,0-1.4.2,2.4,2.4,0,0,0-1,1,2,2,0,0,0-1.1.9,2.7,2.7,0,0,0-.5,1.5,6.3,6.3,0,0,0-.2,2.4,3,3,0,0,0,.3,1.6A2.2,2.2,0,0,0,30.8,435.9Z',
  'M58.8,442.6a3.1,3.1,0,0,0-.6,1.3,2.9,2.9,0,0,0,.3,1.6,5.5,5.5,0,0,0,1,2.2c0,.2.1.3.2.5a15,15,0,0,1,9.4-3.3,1.6,1.6,0,0,0-.2-1,4.7,4.7,0,0,0-1.1-2.6,1.9,1.9,0,0,0-.8-1.1,3.5,3.5,0,0,0-1.4-.6l-1.3-.5a2.5,2.5,0,0,0-1.5.3,4.4,4.4,0,0,0-2.2,1,2,2,0,0,0-1.3.9A2.1,2.1,0,0,0,58.8,442.6Z',
  'M45.4,457.7H44a2.8,2.8,0,0,0-1.4,1,5.2,5.2,0,0,0-1.6,1.7l-.4.4a15.3,15.3,0,0,1,6.9,7.3,1.6,1.6,0,0,0,.8-.6,4.6,4.6,0,0,0,1.9-2,2.1,2.1,0,0,0,.7-1.2,2.6,2.6,0,0,0-.1-1.5,1.7,1.7,0,0,0,0-1.4,2.4,2.4,0,0,0-.9-1.3,4.4,4.4,0,0,0-1.7-1.6,3.4,3.4,0,0,0-1.4-.9A3.2,3.2,0,0,0,45.4,457.7Z',
  'M46.2,485.8a2.3,2.3,0,0,0,.6,2.8,4.7,4.7,0,0,0,1.5,1.9c.1.1.2.3.4.4a14.2,14.2,0,0,1,8.1-5.9,3.6,3.6,0,0,0-.5-.9,5.5,5.5,0,0,0-1.8-2.2,2.5,2.5,0,0,0-1.2-.8,2.3,2.3,0,0,0-1.4-.1,3.2,3.2,0,0,0-1.4-.2,2.5,2.5,0,0,0-1.4.8,5.5,5.5,0,0,0-1.9,1.5,3,3,0,0,0-.9,1.3A2.3,2.3,0,0,0,46.2,485.8Z',
  'M60.9,510.5a2.4,2.4,0,0,0-1.4.5,3.8,3.8,0,0,0-.9,1.3,9.2,9.2,0,0,0-.9,2.3c-.1.1-.1.3-.2.5a15.1,15.1,0,0,1,9,4.4,2.1,2.1,0,0,0,.6-.8,6.4,6.4,0,0,0,1.1-2.7,5.9,5.9,0,0,0,.2-1.4,3.8,3.8,0,0,0-.6-1.3,3.5,3.5,0,0,0-.5-1.3,3.8,3.8,0,0,0-1.3-.9,6,6,0,0,0-2.2-.9,3,3,0,0,0-1.6-.3A2.6,2.6,0,0,0,60.9,510.5Z',
  'M62.1,526.2a1.7,1.7,0,0,0-1.4,0,2.6,2.6,0,0,0-1.4.9,5.2,5.2,0,0,0-1.6,1.7.8.8,0,0,1-.4.4,15.9,15.9,0,0,1,6.9,7.4l.8-.6a4.8,4.8,0,0,0,1.9-2.1,1.9,1.9,0,0,0,.7-1.2,3.3,3.3,0,0,0,0-1.4,2.2,2.2,0,0,0-.1-1.4,2,2,0,0,0-.8-1.3,5.8,5.8,0,0,0-1.8-1.7,2.5,2.5,0,0,0-1.4-.8A2.2,2.2,0,0,0,62.1,526.2Z',
  'M84.7,542.5a3,3,0,0,0,2.7-1.2,5.8,5.8,0,0,0,1.5-1.9l.3-.4a15.6,15.6,0,0,1-7.4-6.7,1.6,1.6,0,0,0-.8.6,4.7,4.7,0,0,0-1.7,2.2,3.1,3.1,0,0,0-.6,1.3,2.2,2.2,0,0,0,.2,1.4,2.3,2.3,0,0,0,.1,1.4,2.3,2.3,0,0,0,1,1.2,4,4,0,0,0,1.9,1.5,1.7,1.7,0,0,0,1.4.7A2.2,2.2,0,0,0,84.7,542.5Z',
  'M95.5,559.3c-3.5.9-7.2-1.8-8.4-6a.7.7,0,0,0-.1-.5h-.2a4,4,0,0,0-1.9,1.1,3.4,3.4,0,0,0-1.8,1.1,3.7,3.7,0,0,0-1.1,1.9,8,8,0,0,0-1.1,4,5,5,0,0,0,0,2.1,5.9,5.9,0,0,0,1,1.7l.2.2a3.3,3.3,0,0,0,1,1.7,3.6,3.6,0,0,0,1.7,1.1,8.7,8.7,0,0,0,4.1,1.1,5,5,0,0,0,2.1,0,4,4,0,0,0,1.9-1.1,3.4,3.4,0,0,0,1.8-1.1,3.7,3.7,0,0,0,1.1-2,8.8,8.8,0,0,0,1-3.5,4.9,4.9,0,0,0,.1-2.4A4.2,4.2,0,0,1,95.5,559.3Z',
  'M97.3,616.2h1.4a2.8,2.8,0,0,0,1.3-.9,5.2,5.2,0,0,0,1.6-1.7l.4-.3a14.2,14.2,0,0,1-6.3-7.4l-.9.6a4.6,4.6,0,0,0-1.9,2,2.4,2.4,0,0,0-.7,1.2,3.3,3.3,0,0,0,0,1.4,3.3,3.3,0,0,0,0,1.4,2.6,2.6,0,0,0,.8,1.3,4.9,4.9,0,0,0,1.6,1.6,2.7,2.7,0,0,0,2.7.8Z',
  'M75,625.6a3.2,3.2,0,0,0-.3,1.4,3.4,3.4,0,0,0,.6,1.5,5,5,0,0,0,1.3,1.9l.3.4a15.3,15.3,0,0,1,8.4-5,3.6,3.6,0,0,0-.4-.9,5.1,5.1,0,0,0-1.5-2.3,2.2,2.2,0,0,0-1-.9,2,2,0,0,0-1.3-.2,1.7,1.7,0,0,0-1.4-.3,2.4,2.4,0,0,0-1.4.5,5.2,5.2,0,0,0-1.9,1.4,2,2,0,0,0-1.1,1.1A3.9,3.9,0,0,0,75,625.6Z',
  'M86.3,651.2a3.3,3.3,0,0,0-1.3.4,2.3,2.3,0,0,0-1,1.2,7.2,7.2,0,0,0-1.2,2.1l-.2.5a13.9,13.9,0,0,1,8.1,5.2,2.7,2.7,0,0,0,.7-.8,5.1,5.1,0,0,0,1.3-2.4A3.2,3.2,0,0,0,93,656a2,2,0,0,0-.4-1.3,2.8,2.8,0,0,0-.3-1.3,2.6,2.6,0,0,0-1.1-1,4.5,4.5,0,0,0-2.1-1.2,2.4,2.4,0,0,0-1.5-.4A3.3,3.3,0,0,0,86.3,651.2Z',
  'M113,654.8a2.3,2.3,0,0,0-.1-1.4,2.2,2.2,0,0,0-.9-1.2,4.9,4.9,0,0,0-1.6-1.6l-.4-.4a15.3,15.3,0,0,1-7.1,6.9,1.6,1.6,0,0,0,.6.8,4.1,4.1,0,0,0,1.9,1.8,2.5,2.5,0,0,0,1.2.7,2.2,2.2,0,0,0,1.4-.1,3.3,3.3,0,0,0,1.4,0,2.7,2.7,0,0,0,1.2-.9,5.5,5.5,0,0,0,1.6-1.8,2.2,2.2,0,0,0,.8-1.4A3.3,3.3,0,0,0,113,654.8Z',
  'M82.8,692.1a2,2,0,0,0-1.1.9,2.4,2.4,0,0,0-.5,1.6,4.5,4.5,0,0,0-.3,2.3v.6a15.4,15.4,0,0,1,9.9,1.6l.3-.9a6.7,6.7,0,0,0,.3-2.8,3.2,3.2,0,0,0-.3-1.4,2,2,0,0,0-.9-1.1,2.4,2.4,0,0,0-.9-1.1,1.9,1.9,0,0,0-1.4-.5,5.2,5.2,0,0,0-2.5-.3,2.6,2.6,0,0,0-1.5.2A2.5,2.5,0,0,0,82.8,692.1Z',
  'M106.4,699.5a3.5,3.5,0,0,0,1.1,1,2.2,2.2,0,0,0,1.6.1,4,4,0,0,0,2.4-.2h.6a14.9,14.9,0,0,1-.4-10h-1a5.4,5.4,0,0,0-2.8.3,2.4,2.4,0,0,0-1.3.5,3.1,3.1,0,0,0-.9,1.1,2.4,2.4,0,0,0-.9,1.1,2.6,2.6,0,0,0-.2,1.5,6.3,6.3,0,0,0,.2,2.4,3,3,0,0,0,.5,1.6A2.1,2.1,0,0,0,106.4,699.5Z',
  'M101.9,719c-3.1-1.8-3.7-6.4-1.5-10.2l.3-.4h-.3a3.6,3.6,0,0,0-2-.6,3.7,3.7,0,0,0-2.1-.6,3.6,3.6,0,0,0-2,.6,7.5,7.5,0,0,0-3.7,2,4,4,0,0,0-1.5,1.4,4.9,4.9,0,0,0-.5,2c0,.1-.1.1-.1.2a4.6,4.6,0,0,0-.5,2,3.8,3.8,0,0,0,.5,1.9,6.9,6.9,0,0,0,2.1,3.7,4.3,4.3,0,0,0,1.4,1.6,5.4,5.4,0,0,0,2.1.5,3.6,3.6,0,0,0,2,.6,3.7,3.7,0,0,0,2.3-.7,7.9,7.9,0,0,0,3.1-1.7,4,4,0,0,0,1.8-1.6A4.2,4.2,0,0,1,101.9,719Z',
  'M94.2,740.5a2.4,2.4,0,0,0,.9-1.1,2.9,2.9,0,0,0,.1-1.6,5,5,0,0,0-.3-2.4c0-.2-.1-.3-.1-.5a15,15,0,0,1-10,.7,3.4,3.4,0,0,0-.1,1,5.5,5.5,0,0,0,.4,2.8,3.5,3.5,0,0,0,.5,1.3,2,2,0,0,0,1.2.8,2.4,2.4,0,0,0,1.1.9,2.2,2.2,0,0,0,1.6.1,5,5,0,0,0,2.4-.3,3,3,0,0,0,1.5-.5A3.6,3.6,0,0,0,94.2,740.5Z',
  'M78.4,744.4a3.2,3.2,0,0,0-1-1,2.2,2.2,0,0,0-1.6-.3,6.4,6.4,0,0,0-2.4-.1h-.5a14.9,14.9,0,0,1-.8,10,1.8,1.8,0,0,0,1,.2,5.3,5.3,0,0,0,2.8.1,2.3,2.3,0,0,0,1.4-.4,2.4,2.4,0,0,0,1-1,2.2,2.2,0,0,0,1-.9,2.9,2.9,0,0,0,.4-1.6,4.9,4.9,0,0,0,0-2.4,2.1,2.1,0,0,0-.3-1.5A2.7,2.7,0,0,0,78.4,744.4Z',
  'M58.2,764a3.1,3.1,0,0,0-1.1.9,2,2,0,0,0-.6,1.5,4.7,4.7,0,0,0-.3,2.4v.5a15.2,15.2,0,0,1,9.8,1.9,3.2,3.2,0,0,0,.3-.9,5.3,5.3,0,0,0,.4-2.8,2.3,2.3,0,0,0-.2-1.4,2,2,0,0,0-.9-1.1,2,2,0,0,0-.8-1.2,3,3,0,0,0-1.5-.5,4.8,4.8,0,0,0-2.4-.3,2.9,2.9,0,0,0-1.6.1A3.1,3.1,0,0,0,58.2,764Z',
  'M49.9,791.5a2.5,2.5,0,0,0-2.8-.4,5,5,0,0,0-2.3.9l-.5.2a15,15,0,0,1,2.8,9.6l1-.2a6.2,6.2,0,0,0,2.7-.9l1.1-.8a3.8,3.8,0,0,0,.6-1.3,3.1,3.1,0,0,0,.6-1.3,2.9,2.9,0,0,0-.2-1.6,5.3,5.3,0,0,0-.8-2.2,2.2,2.2,0,0,0-.9-1.4A3.1,3.1,0,0,0,49.9,791.5Z',
  'M76.5,791.5c-3-2-3.6-6.6-1.2-10.3l.3-.4h-.2a4.2,4.2,0,0,0-2.1-.6,3.5,3.5,0,0,0-2-.7,3.6,3.6,0,0,0-2.1.5,7.7,7.7,0,0,0-3.7,1.9,4,4,0,0,0-1.5,1.4,3.5,3.5,0,0,0-.6,1.9.4.4,0,0,0-.1.3,3.9,3.9,0,0,0-.6,1.9,3.5,3.5,0,0,0,.5,2,6.6,6.6,0,0,0,1.9,3.7,4.3,4.3,0,0,0,1.4,1.6,4.2,4.2,0,0,0,2.1.6,4,4,0,0,0,2,.7,4.6,4.6,0,0,0,2.3-.6,9,9,0,0,0,3.2-1.7,4.3,4.3,0,0,0,1.8-1.5Z',
  'M31.6,811.7a2.2,2.2,0,0,0-1.4,0,2.5,2.5,0,0,0-1.4.8,5.5,5.5,0,0,0-1.8,1.6l-.4.4a14.7,14.7,0,0,1,6.3,7.8l.9-.5a7.6,7.6,0,0,0,2.1-1.9,2.4,2.4,0,0,0,.7-1.2,2.3,2.3,0,0,0,.1-1.4,2.6,2.6,0,0,0,0-1.5,2.1,2.1,0,0,0-.7-1.3,5.8,5.8,0,0,0-1.7-1.8,2.5,2.5,0,0,0-1.3-.9A2.2,2.2,0,0,0,31.6,811.7Z',
  'M68.1,812.7a2,2,0,0,0,.9-1.1,2.3,2.3,0,0,0,.2-1.6,6.5,6.5,0,0,0-.2-2.4,4.3,4.3,0,0,1-.1-.5,15,15,0,0,1-10,.4,1.7,1.7,0,0,0-.1,1,4.5,4.5,0,0,0,.3,2.8,2.1,2.1,0,0,0,.5,1.3,3.1,3.1,0,0,0,1.1.9,2,2,0,0,0,1.1.9,2.6,2.6,0,0,0,1.5.2,5,5,0,0,0,2.4-.3,2,2,0,0,0,1.5-.5A1.9,1.9,0,0,0,68.1,812.7Z',
  'M62.4,847.3c-3.6.7-7.1-2.3-7.9-6.6a.9.9,0,0,0-.1-.5h-.2a3.9,3.9,0,0,0-1.9,1,4,4,0,0,0-2,.9,3.6,3.6,0,0,0-1.2,1.8,7.7,7.7,0,0,0-1.4,3.9,5,5,0,0,0-.1,2.1,3.1,3.1,0,0,0,.9,1.8v.2a3.7,3.7,0,0,0,.8,1.8,4.8,4.8,0,0,0,1.7,1.2,8.4,8.4,0,0,0,4,1.4,3.8,3.8,0,0,0,2.1.1,3.1,3.1,0,0,0,1.9-.9,4,4,0,0,0,2-.9,3.7,3.7,0,0,0,1.2-2,7,7,0,0,0,1.2-3.4,3.8,3.8,0,0,0,.3-2.4A3.4,3.4,0,0,1,62.4,847.3Z',
  'M43,865.5a3.5,3.5,0,0,0-1.3.5,1.9,1.9,0,0,0-1,1.2,4.6,4.6,0,0,0-1.1,2.2l-.3.5A14.7,14.7,0,0,1,48,875c.2-.3.5-.5.6-.8a5.8,5.8,0,0,0,1.3-2.6,2.8,2.8,0,0,0,.3-1.3,3.4,3.4,0,0,0-.5-1.4,2.2,2.2,0,0,0-.4-1.3,2.3,2.3,0,0,0-1.2-1,5.7,5.7,0,0,0-2.2-1.1,2.6,2.6,0,0,0-1.5-.4A3.3,3.3,0,0,0,43,865.5Z',
  'M70.8,868.6a2.6,2.6,0,0,0-1.1-2.7,4.6,4.6,0,0,0-1.8-1.6l-.4-.3a15.5,15.5,0,0,1-7,7.1,1.6,1.6,0,0,0,.6.8,4.6,4.6,0,0,0,2.1,1.8,3.3,3.3,0,0,0,1.3.7,2.2,2.2,0,0,0,1.4-.2h1.4a3.2,3.2,0,0,0,1.3-1,4.4,4.4,0,0,0,1.5-1.8,2.5,2.5,0,0,0,.8-1.4A2.2,2.2,0,0,0,70.8,868.6Z',
  'M67.1,898.9a3.4,3.4,0,0,0,1.4-.2,4.3,4.3,0,0,0,1.2-1.1,4.8,4.8,0,0,0,1.4-2l.3-.4a14.8,14.8,0,0,1-7.7-6.4,2.7,2.7,0,0,0-.8.7,5.3,5.3,0,0,0-1.6,2.3,2.1,2.1,0,0,0-.5,1.3,2.2,2.2,0,0,0,.2,1.4,2.2,2.2,0,0,0,.2,1.4,2.5,2.5,0,0,0,1.1,1.2,5.2,5.2,0,0,0,1.9,1.4,2.5,2.5,0,0,0,1.5.6A2.2,2.2,0,0,0,67.1,898.9Z',
  'M45.9,911.8a3.2,3.2,0,0,0-.1,1.4,3.4,3.4,0,0,0,.9,1.4,4.4,4.4,0,0,0,1.6,1.7.8.8,0,0,0,.4.4,14.4,14.4,0,0,1,7.7-6.3,4.1,4.1,0,0,0-.5-.9,5.2,5.2,0,0,0-1.9-2,2,2,0,0,0-1.2-.8,1.7,1.7,0,0,0-1.4,0,2.3,2.3,0,0,0-1.4-.1,2.5,2.5,0,0,0-1.4.8,5.5,5.5,0,0,0-1.8,1.6,3.4,3.4,0,0,0-.9,1.4A3.3,3.3,0,0,0,45.9,911.8Z',
  'M89.8,935.1a3.9,3.9,0,0,0-.3-1.4,2,2,0,0,0-1.1-1.1,4.4,4.4,0,0,0-2-1.3.5.5,0,0,0-.5-.3,14.5,14.5,0,0,1-6,7.9,2.7,2.7,0,0,0,.7.8,5.1,5.1,0,0,0,2.3,1.5,3.4,3.4,0,0,0,1.4.5,2.8,2.8,0,0,0,1.3-.3,3.2,3.2,0,0,0,1.4-.3,2.5,2.5,0,0,0,1.2-1.1,4.6,4.6,0,0,0,1.3-2,3.4,3.4,0,0,0,.6-1.5A3.2,3.2,0,0,0,89.8,935.1Z',
  'M61.9,935.7a1.6,1.6,0,0,0-1.3.6,2,2,0,0,0-.9,1.3,6.3,6.3,0,0,0-.8,2.3.9.9,0,0,0-.1.5,14.6,14.6,0,0,1,9.1,4,2.4,2.4,0,0,0,.6-.9,5.1,5.1,0,0,0,.9-2.7,2.2,2.2,0,0,0,.1-1.4,1.7,1.7,0,0,0-.6-1.2,2.6,2.6,0,0,0-.6-1.3A2.4,2.4,0,0,0,67,936a6.3,6.3,0,0,0-2.3-.8,2.9,2.9,0,0,0-1.6-.2A2.4,2.4,0,0,0,61.9,935.7Z',
  'M121.9,937.5a3.6,3.6,0,0,0-1.2-.8,2.2,2.2,0,0,0-1.6,0,5.9,5.9,0,0,0-2.3.4l-.6.2a14.8,14.8,0,0,1,1.3,9.9h1a5.6,5.6,0,0,0,2.8-.5,2.7,2.7,0,0,0,1.2-.6,2.8,2.8,0,0,0,.8-1.2,2,2,0,0,0,.8-1.2,2.6,2.6,0,0,0,.1-1.5,4.9,4.9,0,0,0-.5-2.4,2.2,2.2,0,0,0-.6-1.5A3.2,3.2,0,0,0,121.9,937.5Z',
  'M106,960.7a4.8,4.8,0,0,0-.9,1.1,3,3,0,0,0-.2,1.6,6.5,6.5,0,0,0,.1,2.4,4.3,4.3,0,0,0,.1.5,15.6,15.6,0,0,1,10,0,4.4,4.4,0,0,0,.2-1,6.5,6.5,0,0,0-.2-2.8,3.4,3.4,0,0,0-.5-1.4,3.1,3.1,0,0,0-1.1-.9,2,2,0,0,0-1.1-.9,2,2,0,0,0-1.5-.3,6.5,6.5,0,0,0-2.4.2,2.1,2.1,0,0,0-1.5.5A1.9,1.9,0,0,0,106,960.7Z',
  'M92,1004.6a3.2,3.2,0,0,0,.3,1.4,2.5,2.5,0,0,0,1.2,1.1,4.6,4.6,0,0,0,2,1.3l.5.3a14.7,14.7,0,0,1,5.8-8.2,2.3,2.3,0,0,0-.7-.7,6.1,6.1,0,0,0-2.4-1.5,3.3,3.3,0,0,0-1.4-.4,2.8,2.8,0,0,0-1.3.3,2.3,2.3,0,0,0-1.4.4,2,2,0,0,0-1.1,1.1,4.6,4.6,0,0,0-1.3,2,2.7,2.7,0,0,0-.5,1.5A3.2,3.2,0,0,0,92,1004.6Z',
  'M125.5,1006.5a2.4,2.4,0,0,0,.7-1.3,2.9,2.9,0,0,0-.1-1.6,4.9,4.9,0,0,0-.8-2.3c0-.2-.1-.3-.1-.5a15.6,15.6,0,0,1-9.8,2.4,3.4,3.4,0,0,0,.1,1,6.2,6.2,0,0,0,.9,2.7,2.9,2.9,0,0,0,.7,1.2,3.1,3.1,0,0,0,1.3.6,2.1,2.1,0,0,0,1.3.7,2.6,2.6,0,0,0,1.5-.1,4.9,4.9,0,0,0,2.3-.8,2.3,2.3,0,0,0,1.4-.8A2,2,0,0,0,125.5,1006.5Z',
  'M100,1027.9a2.2,2.2,0,0,0,.1,1.4,2.1,2.1,0,0,0,.9,1.3,6,6,0,0,0,1.9,1.6l.4.3a13.9,13.9,0,0,1,7-7.1,7,7,0,0,0-.6-.9,6.5,6.5,0,0,0-2.2-1.8,2.6,2.6,0,0,0-1.3-.6,2.3,2.3,0,0,0-1.4.1,2.3,2.3,0,0,0-1.4.1,2.7,2.7,0,0,0-1.2.9,4.9,4.9,0,0,0-1.6,1.9,2.3,2.3,0,0,0-.6,2.8Z',
  'M139.2,1037.4a2.5,2.5,0,0,0,.8-1.2,2.9,2.9,0,0,0-.1-1.6,5.9,5.9,0,0,0-.5-2.3l-.2-.6a14.7,14.7,0,0,1-9.8,1.7,2.1,2.1,0,0,0,0,1.1,5.2,5.2,0,0,0,.7,2.7,2.3,2.3,0,0,0,.6,1.2,4.2,4.2,0,0,0,1.2.8,3.3,3.3,0,0,0,1.3.7,2,2,0,0,0,1.5,0,5.3,5.3,0,0,0,2.4-.6,2.7,2.7,0,0,0,1.4-.7A1.9,1.9,0,0,0,139.2,1037.4Z',
]
