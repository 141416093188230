import React from 'react'
import Loadable from 'react-loadable'
import PubSub from 'PubSub'
import { graphql } from 'gatsby'
import throttle from '../components/throttle'

import FrameSVGAnimation from '../components/FrameSVGAnimation'

import TrailPanelTwo from '../components/animated/TrailPanelTwo'
import TrailPanelThree from '../components/animated/TrailPanelThree'
import TrailPanelFour from '../components/animated/TrailPanelFour'
import TrailPanelFive from '../components/animated/TrailPanelFive'
import TrailPanelSix from '../components/animated/TrailPanelSix'
import TrailPanelSeven from '../components/animated/TrailPanelSeven'

import FollowTheCookie from '../components/SVG/FollowTheCookie'

import Percent from '../images/1x/percent-fs8.png'
import ThinMistletoe from '../images/1x/thin-mistletoe-fs8.png'
import BittenCookie from '../images/1x/bitten-cookie-fs8.png'

export const pubsub = new PubSub()

const Layout = Loadable({
  loader: () => import('../components/layout'),
  loading: () => '',
})

const Modal = Loadable({
  loader: () => import('../components/Modal'),
  loading: () => '',
})

const Panel = Loadable({
  loader: () => import('../components/Panel'),
  loading: () => '',
})

const Lottie = Loadable({
  loader: () => import('../components/Lottie'),
  loading: () => '',
})

const FadeIn = Loadable({
  loader: () => import('../components/FadeIn'),
  loading: () => '',
})

const Hider = Loadable({
  loader: () => import('../components/Hider'),
  loading: () => '',
})

const Trail = Loadable({
  loader: () => import('../components/animated/Trail'),
  loading: () => '',
})

const Trails = [
  {
    data: TrailPanelTwo,
    viewBox: '0 460 290 100',
    styles: {
      width: '16vw',
      marginLeft: '-11vw',
    },
  },
  {
    data: TrailPanelThree,
    viewBox: '0 475 150 100',
    styles: {
      width: '8vw',
      marginLeft: '-3.5vw',
    },
  },
  {
    data: TrailPanelFour,
    viewBox: '0 495 230 100',
    styles: {
      width: '12vw',
      marginLeft: '-6vw',
    },
  },
  {
    data: TrailPanelFive,
    viewBox: '0 495 250 100',
    styles: {
      width: '13vw',
      marginLeft: '-5vw',
    },
  },
  {
    data: TrailPanelSix,
    viewBox: '0 500 250 100',
    styles: {
      width: '13vw',
      marginLeft: '-5vw',
    },
  },
  {
    data: TrailPanelSeven,
    viewBox: '0 570 250 100',
    styles: {
      width: '12vw',
      marginLeft: '-5vw',
    },
  },
]

class IndexPage extends React.Component {
  state = {
    openModal: 'none',
    panels: 1,
    windowHeight: 0,
    pageHeight: 0,
    last: false,
  }

  componentDidMount() {
    setTimeout(this.toggleModal, 2000)
    this.___gatsby = document.getElementById('___gatsby')
    this.body = document.body

    const scrollThrottle = throttle(this.handleScroll, 300)

    const passiveIfSupported = false
    document.body.addEventListener('scroll', scrollThrottle, passiveIfSupported)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.getPageHeight().then(this.checkPanels)
  }

  componentWillUnmount() {
    document.body.removeEventListener('scroll', this.handleScroll, false)
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    return (
      <Layout>
        <Modal open={this.state.openModal} onClick={this.toggleModal} />
        <Panel
          className="WoodBackground"
          style={{
            paddingBottom: 0,
          }}
        >
          <div className="Frame">
            <div className="frame-svg">
              <Hider hidden={false}>
                <FrameSVGAnimation />
              </Hider>
            </div>
          </div>
          <div className="PanelRow">
            <div className="PanelContent welcome text-align-center middle no-border">
              <h1 className="happy-holidays">Happy holidays</h1>
              <h2>technology marketers!</h2>
            </div>
          </div>

          <div className="DesktopPanelRow follow-the-cookie">
            {FollowTheCookie()}
          </div>
        </Panel>
        {this.renderPanels()}
      </Layout>
    )
  }

  renderPanels() {
    const panels = this.props.data.prisma.panelses.slice(0, this.state.panels)

    return panels.map(this.renderPanel)
  }

  renderPanel = ({ title, children, flip }, index) => {
    const { even, attributes, color } = this.getAttributes(index)

    const trail = Trails[index]
    const last =
      index + 1 === this.props.data.prisma.panelses.length && this.state.last

    return (
      <Panel key={`panel-${index}`} {...attributes}>
        <div className={`PanelContent ${color} text-align-center`}>
          <FadeIn windowHeight={this.state.windowHeight} last={last}>
            {this.renderPanelTitle(title)}

            <div className="PanelInner">
              {this.renderChildren(children, flip)}
            </div>
          </FadeIn>
        </div>

        {this.renderPanelDeco(index, even)}

        {this.renderPanelTrail(trail, last)}
      </Panel>
    )
  }

  renderPanelTitle(title = null) {
    return title ? <h3 className="PanelTitle">{title}</h3> : null
  }

  renderPanelDeco(index, even) {
    if (index + 1 === this.props.data.prisma.panelses.length) {
      return this.renderBottomFrame()
    }

    if (index + 2 !== this.props.data.prisma.panelses.length) {
      return (
        <div className={`PanelDeco ${even ? '' : 'swapped'}`}>
          <img
            src={BittenCookie}
            className="PanelDeco__img bitten-cookie"
            alt=""
          />
          <img
            src={ThinMistletoe}
            className="PanelDeco__img thin-mistletoe"
            alt=""
          />
        </div>
      )
    }

    return null
  }

  renderBottomFrame() {
    return (
      <div
        className="frame-svg frame-bottom"
        style={{
          position: 'absolute',
          left: 0,
          bottom: -100,
          right: 0,
          transform: 'rotate(180deg)',
        }}
      >
        {FrameSVGAnimation(false)}
      </div>
    )
  }

  renderPanelTrail({ styles, viewBox, data }, last) {
    return (
      <Trail
        data={data}
        className="trail"
        style={styles}
        viewBox={viewBox}
        windowHeight={this.state.windowHeight}
        last={last}
      />
    )
  }

  getAttributes(index) {
    const last = this.props.data.prisma.panelses.length - 1

    return index % 2 === 0
      ? {
          even: true,
          attributes: {
            className: `GreyBackground dark flex ${
              index === last ? 'last' : ''
            }`,
          },
          color: 'dark',
        }
      : {
          even: false,
          attributes: {
            className: `WoodBackground flex ${index === last ? 'last' : ''}`,
          },
          color: 'light',
        }
  }

  renderChildren(children, flip) {
    if (flip) {
      return Array(...children)
        .reverse()
        .map(this.renderQuant)
    }

    return children.map(this.renderQuant)
  }

  renderQuant = child => {
    const {
      title,
      subtitle,
      content,
      lottie,
      lottieViewBox,
      percent,
      flip,
    } = child.quant

    return flip ? (
      <div className="Quant flipped" key={`quant-${child.quant.id}`}>
        {this.renderLottie(lottie, lottieViewBox)}
        {this.renderQuantContent({ title, percent, subtitle, content })}
      </div>
    ) : (
      <div className="Quant" key={`quant-${child.quant.id}`}>
        {this.renderQuantContent({ title, percent, subtitle, content })}
        {this.renderLottie(lottie, lottieViewBox)}
      </div>
    )
  }

  renderQuantContent({ title, percent, subtitle, content }) {
    const display = title || subtitle || content

    return display ? (
      <div className="QuantContent" key={`${title}-quant`}>
        {title ? this.renderQuantTitle(title, percent) : null}
        {subtitle ? this.renderQuantSubTitle(subtitle) : null}
        {content ? this.renderQuantParagraph(content) : null}
      </div>
    ) : null
  }

  renderQuantTitle(title, percent) {
    return (
      <h4>
        {title}
        {this.renderPercent(percent)}
      </h4>
    )
  }

  renderQuantSubTitle(subtitle) {
    return <h3 dangerouslySetInnerHTML={{ __html: subtitle }} />
  }

  renderQuantParagraph(content) {
    return <p dangerouslySetInnerHTML={{ __html: content }} />
  }

  renderPercent(percent) {
    return percent ? <img src={Percent} className="Percent" /> : null
  }

  renderLottie(lottie, lottieViewBox) {
    if (JSON.stringify(lottie) !== '{}') {
      let attributes = {}

      if (lottie.file !== undefined) {
        attributes.path = lottie.file
      } else {
        attributes.animationData = lottie
      }

      return (
        <Hider className="QuantLottie">
          <Lottie viewBoxSize={lottieViewBox} attributes={attributes} />
        </Hider>
      )
    }

    return null
  }

  toggleModal = () => {
    this.setState({
      openModal:
        this.state.openModal === ''
          ? 'open'
          : this.state.openModal === 'open'
            ? 'closed'
            : 'open',
    })
  }

  checkPanels = () => {
    if (this.props.data.prisma.panelses.length <= this.state.panels) {
      return
    }

    const { windowHeight } = this.state
    const body = this.body

    if (body.scrollTop + windowHeight > body.scrollHeight - windowHeight / 2) {
      this.setState(
        {
          panels: this.state.panels + 1,
        },
        this.timedGetPageHeight,
      )
    }
  }

  handleScroll = () => {
    const body = this.body
    if (body.scrollTop === body.scrollHeight - this.state.windowHeight) {
      this.setState({ last: true })
    }

    pubsub.publish('scrolled')
    this.checkPanels()
  }

  handleResize = () => {
    this.setState({ windowHeight: window.innerHeight })
  }

  timedGetPageHeight = () => {
    setTimeout(this.getPageHeight, 200)
  }

  getPageHeight = () => new Promise(this.setPageHeight)

  setPageHeight = resolve => {
    this.setState(
      {
        pageHeight: this.___gatsby.getBoundingClientRect().height,
      },
      resolve,
    )
  }
}

export const query = graphql`
  query {
    prisma {
      panelses {
        title
        flip
        children {
          quant {
            id
            title
            subtitle
            lottie
            lottieViewBox
            percent
            content
            flip
          }
        }
      }
      frame: lottieses(where: { title: "frame" }) {
        lottie
      }
    }
  }
`

export default IndexPage
